import React from 'react';
import './style.scss';
import { Button } from 'react-bootstrap';
import { Cookie } from '../../Assets';
import { Link } from 'react-router-dom';

const Cookies = ({ accept }) => 
   (
    <div className="mt-5 h-100">
      <div className="p-3 text-center cookies">
        <span className="mt-2 mr-2 align-text-top">
          <img src={Cookie} alt="" width="50" className="mr-2" />
          We rely on cookies for authentication and personalisation purposes. By continuing to use
          this site you must agree to our <Link to="/privacy">cookie policy</Link>.
          <Button onClick={accept} className="ml-2">
            Okay
          </Button>
        </span>
      </div>
    </div>
  );


export default Cookies;
