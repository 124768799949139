import axios from 'axios';
import { useEffect } from 'react';

const Auth = ({ state, setState, children }) => {
  // const TIME_REMAINING = 500000000;
  useEffect(() => {
    fetchData();
    // const intervalId = setInterval(() => {
    //   fetchData();
    // }, TIME_REMAINING);

    // return () => clearInterval(intervalId);
  }, []);

  const authenticate = ({ data }) => 

    setState((previousState) => ({
        ...previousState,
        token: data,
        loggedIn: true,
        loading: false
  }))
  
  async function fetchData() {
    await axios
      .get('/login/refresh_token')
      .then(authenticate)
      .catch(() => setState((previousState) => ({ ...previousState, loading: false })));
  }

  return <>{children}</>;
};

export default Auth;
