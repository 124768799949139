import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Login as LoginView } from '../../Components';
import { Eye, EyeSlash } from '../../Assets';
import tracker from '../../HOC/Tracker';

tracker();

const Login = ({ login }) => {
  const history = useHistory();

  const INITIAL_STATE = {
    username: '',
    password: '',
    variant: 'danger',
    message: '',
    show: false,
    type: 'password',
    eye: Eye
  };

  const [state, setState] = useState(INITIAL_STATE);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((previousState) => ({
      ...previousState,
      [name]: value
    }));
  };

  const handleSuccess = ({ data }) => {

    login(data.token, data.username, data.organisation, data.admin)

    data.admin ? history.push('/stories/manage') : history.push('/stories/our');
  };

  const handleResponse = (code) => {
    switch (code) {
      case 404:
        return 'Sorry, an account with that username was not found! Please try again.';

      case 401:
        return "Username and password don't match! Please try again.";

      case 429:
        return 'Too many login attempts from this address, please try again after an hour.';

      default:
        return 'Unexpected error occurred when attempting to login, please try again.';
    }
  };

  const handleError = ({ response: { status } }) => {
    const ERROR_MESSAGE = handleResponse(status);

    setState((previousState) => ({
      ...previousState,
      message: ERROR_MESSAGE,
      variant: 'danger',
      show: true
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const DATA = {
      username: state.username,
      password: state.password
    };

    axios.post('/login', DATA).then(handleSuccess).catch(handleError);
  };

  return (
    <LoginView
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleClick={(e) =>
        setState((previousState) => ({
          ...previousState,
          type: state.type === 'password' ? 'text' : 'password',
          eye: state.eye === Eye ? EyeSlash : Eye
        }))
      }
      type={state.type}
      eye={state.eye}
      message={state.message}
      variant={state.variant}
      show={state.show}
    />
  );
};

export default Login;
