import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import Banner from './view';

const Hero = ({ state, setState, token, user, type }) => {
  const [bannerState, setBannerState] = useState({
    file: null,
    show: false,
    message: '',
    variant: 'success'
  });

  const handleError = (e) =>
    setBannerState({
      ...bannerState,
      show: true,
      message: 'Image failed to upload.',
      variant: 'danger'
    });

  const handleSuccess = ({ data }) => {
    const tempState = state;
    tempState[type][type === 'story' ? 'header' : 'banner'] = data;
    setState((previousState) => ({ ...previousState, tempState }));
    setBannerState({
      ...bannerState,
      show: true,
      message: 'Image uploaded successfully.',
      variant: 'success'
    });
  };

  const HEADERS = {
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data'
    }
  };

  const buildForm = () => {
    let formData = new FormData();
    const id = uuid();
    formData.append('image', bannerState.file[0]);
    formData.append('username', user);
    formData.append('title', id);
    formData.append('type', type);
    formData.append('header', 'true');

    return formData;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const DATA = buildForm();

    axios.post('/media', DATA, HEADERS).then(handleSuccess).catch(handleError);
  };

  return (
    <Banner
      handleSubmit={handleSubmit}
      handleChange={(e) => setBannerState({ ...bannerState, file: e.target.files })}
      variant={bannerState.variant}
      show={bannerState.show}
      message={bannerState.message}
      file={bannerState.file}
      header={state[type][type === 'story' ? 'header' : 'banner']}
    />
  );
};

export default Hero;
