import React, { useRef } from 'react';
import { SEO } from '../../Components'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook as Book, faTag as Tag, faPen as Pen } from '@fortawesome/free-solid-svg-icons';
import { Clavie } from '../../Assets';
import './style.scss';
import ReactPlayer from 'react-player';
import tracker from '../../HOC/Tracker';

tracker();

const Home = () => {
  const myRef = useRef(null);
  const history = useHistory();

  const goToTellingStories = () =>{  
    history.push('/stories/telling');
  }

  const goToSkillsForStories = () =>{  
    history.push('/stories/skills');
  }

  const goToUsingStories = () =>{  
    history.push('/stories/using');
  }

  return (
    <>
      <SEO title="Home" description="NorthWord Home Page" url='https://www.northwordproject.com/' />

      <Container fluid className="home about parallax lander d-flex align-items-center justify-content-center">

        <div className="bird-container bird-container--one">
          <div className="bird bird--one" />
        </div>

        <div className="bird-container bird-container--two">
          <div className="bird bird--two" />
        </div>

        <div className="bird-container bird-container--three">
          <div className="bird bird--three" />
        </div>

        <div className="bird-container bird-container--four">
          <div className="bird bird--four" />
        </div>

      </Container>
      <Container fluid className="bg-primary d-flex align-items-center justify-content-center">
        <Row className='p-md-5 p-sm-0' > 

       
          <Col sm={10} md={6} className='mx-md-5 p-5 my-md-5 my-sm-0 text-contrast-primary text-center'><Row>NorthWord combines traditional storytelling with modern technologies to help increase the visibility and market reach of creative practitioners working in remote areas. It uses stories about places to help creative practitioners promote their products and services.</Row>
            <Row>
           

              <Button onClick={goToTellingStories} variant="secondary" className='mt-3 mx-auto'> 
                <FontAwesomeIcon className="compass" icon={Book} /> Telling Stories
              </Button>

              <Button onClick={goToUsingStories} variant="secondary" className='mt-3 mx-auto'>
                <FontAwesomeIcon className="mr-2" icon={Tag} />Using Stories
              </Button>

              <Button onClick={goToSkillsForStories} variant="secondary" className='mt-3 mx-auto'>
                <FontAwesomeIcon className="mr-2" icon={Pen} />Skills for Stories
              </Button>
            </Row>
          </Col>
          
 <Col sm={6} md={4} className='mx-md-5 p-5 my-auto'><img className='img-fluid' src={Clavie} alt="The Burning of the Clavie" /></Col>
      </Row>
      </Container>

      {/*<Container fluid className="bg-secondary d-flex align-items-center justify-content-center">
        <Row className='p-md-5 p-sm-0' > 

  
          <Col className='mx-auto'><iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fnorthwordstorytagging&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=428536505356301" width="2000" height="1000"  style={{ border: 'none',  overflow: 'hidden', width: '100vw', minWidth: '100%' }} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" />
           {/* <IframeResizer src="https://www.instagram.com/?hl=en" width="340" height="500" style={{ border: 'none', overflow: 'hidden', width: '600px', minWidth: '100%' }} scrolling="no" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" />
          </Col>
      </Row>

  </Container>*/}
      
      <Container fluid className="bg-secondary d-flex align-items-center justify-content-center">
        <Row className='p-md-5 p-sm-0' > 
            <ReactPlayer
            className='react-player'
            url= 'https://northword.blob.core.windows.net/media/documentary.mp4'
            width="100%"
            height="100%"
            playing='true'
            muted={true}
            controls='true'
            id='documentary'
            />
</Row>

  </Container>
      
   

     
      
    </>
  );
};

export default Home;
