import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import View from './view.js';
import tracker from '../../../HOC/Tracker';

tracker();

const ManageUsers = ({ token }) => {
  const [state, setState] = useState({
    show: false,
    visibility: false,
    users: [],
    username: ''
  });

  const HEADERS = useMemo(
    () => ({
      headers: { Authorization: token }
    }),
    [token]
  );

  useEffect(() => {
    axios.get('/user', HEADERS).then((res) =>
      setState((previousState) => ({
        ...previousState,
        users: res.data
      }))
    );
  }, [HEADERS]);

  const handleCancel = () =>
    setState((previousState) => ({
      ...previousState,
      show: false
    }));

  const handleDelete = () =>
    axios.delete('/user/' + state.username, HEADERS).then((res) =>
      setState((previousState) => ({
        ...previousState,
        show: false,
        users: state.users.filter((user) => user.username !== state.username)
      }))
    );

  const handleShow = (e, username) =>
    setState((previousState) => ({
      ...previousState,
      username: username,
      show: true
    }));

  return (
    <View
      users={state.users}
      handleCancel={handleCancel}
      handleShow={handleShow}
      handleDelete={handleDelete}
      state={state}
    />
  );
};

export default ManageUsers;
