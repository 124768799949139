import React from 'react';
import Deck from './Deck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMountain as Mountain,
  faMapPin as Pin,
  faThumbsUp as Thumb
} from '@fortawesome/free-solid-svg-icons';

const TellingStoriesDeck = ({ stories, type }) => {
  const alphabeticalSort = stories.sort((a, b) =>
    a.content[0].title.localeCompare(b.content[0].title)
  );

  const nearest = [...stories].sort((a, b) => a.distance - b.distance);

  const recommended = [...stories].sort((a, b) => b.views - a.views).slice(0, 10);

  return (
    <>
      <Deck
        stories={alphabeticalSort}
        name={
          <>
            <FontAwesomeIcon icon={Mountain} className="mr-1" />
            All Stories
          </>
        }
        byline='Explore the North'
        type={type}
      />

      <Deck
        stories={nearest}
        name={
          <>
            <FontAwesomeIcon icon={Pin} className="mr-1" />
            Stories Near You
          </>
        }
        type={type}
      />
      <Deck
        stories={recommended}
        name={
          <>
            <FontAwesomeIcon icon={Thumb} className="mr-1" />
            Most Viewed Stories
          </>
        }
        type={type}
      />
    </>
  );
};

export default TellingStoriesDeck;
