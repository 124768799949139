import React from 'react';
import { Formik } from 'formik';
import { Jumbotron, Container, Button, Form, Alert, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit as Edit } from '@fortawesome/free-solid-svg-icons';
import { SEO } from '../../../Components';

const ChangePassword = ({ state, schema, handleSubmit, user, toggleEye, toggleConfirmEye }) => (

  <>
    <SEO title='Change Password' description='Change your password for the NorthWord platform.' url='https://www.northwordproject.com/users/changepassword' />
  <Container className="row m-0 p-0" fluid>
    <Jumbotron className="my-lg-5 my-0 h-sm-100 mx-lg-auto mx-0 w-lg-75 w-100">
      <h1>Change Password</h1>
      <h4>{user}</h4>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          password: state.password,
          check: state.check
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control
                  onChange={handleChange}
                  name="password"
                  type={state.type}
                  placeholder="Enter a password..."
                  maxLength="15"
                  value={values.password}
                  isInvalid={touched.password && !!errors.password}
                />
                <Button as={InputGroup.Append} variant="primary" id="show" onClick={toggleEye}>
                  <img src={state.eye} alt="Show password" />
                </Button>
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </InputGroup>
              <Form.Text muted>Enter the password.</Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPasswordCheck">
              <Form.Label>Confirm Password</Form.Label>
              <InputGroup>
                <Form.Control
                  onChange={handleChange}
                  name="check"
                  type={state.confirmType}
                  placeholder="Enter your password again..."
                  maxLength="15"
                  value={values.check}
                  isInvalid={touched.check && !!errors.check}
                />
                <Button
                  as={InputGroup.Append}
                  variant="primary"
                  id="show"
                  onClick={toggleConfirmEye}
                >
                  <img src={state.confirmEye} alt="Show password confirm" />
                </Button>
                <Form.Control.Feedback type="invalid">{errors.check}</Form.Control.Feedback>
              </InputGroup>
              <Form.Text muted>Enter the password again.</Form.Text>
            </Form.Group>

            <Button variant="primary" type="submit">
              <FontAwesomeIcon icon={Edit} className="mr-1" />
              Change Password
            </Button>

            <Alert show={state.show} variant={state.variant}>
              {state.msg}
            </Alert>
          </Form>
        )}
      </Formik>
    </Jumbotron>
    </Container>
    </>
);

export default ChangePassword;
