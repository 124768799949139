import React from 'react';
import { Jumbotron, Container, ToggleButtonGroup, ToggleButton, Row } from 'react-bootstrap';
import { Loader, SEO } from '../../../Components';
import ReactHtmlParser from 'react-html-parser';
import './style.scss';
import Audio from 'react-audio-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt as Pin } from '@fortawesome/free-solid-svg-icons';
import { Lines2 as Lines} from '../../../Assets';

const View = ({ loading, content, location, index, header, handleSelect, preview, id}) => {
  const buildTranslations = () => {
    if (content.length > 1 && !preview) {
      return (
        <ToggleButtonGroup type="radio" name="options" defaultValue={0} onChange={handleSelect}>
          <ToggleButton disabled variant="tertiary">
            Translation:
          </ToggleButton>
          {content.map((translation, idx) => (
            <ToggleButton
              id={`tbg-radio-${idx}`}
              value={idx}
              variant={index === idx ? 'secondary' : 'outline-secondary'}
            >
              {translation.language}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      );
    }
  };

  return loading ? (
    <Loader />
  ) : (
      <>
      <SEO title={content[index].title} description={content[index].summary} url={`https://www.northwordproject.com/stories/${id}` } />
      <Jumbotron
        fluid
        className="p-0 m-0 hero-image"
        style={{
          backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${header.replace(' ',"%20")})`
        }}
          
        >
          <Container className="hero-text px-0">
          <Row>
            <div>
              <h1 className="text multiply">{content[index].title || 'Untitled'}</h1>
            </div>
          </Row>
          <Row>
            <h2>{content[index].subtitle}</h2>
          </Row>
          <Row>
              {location !== '000' && (
                <p>
                  <FontAwesomeIcon icon={Pin} className="mr-1" />
                    {location || 'No Location Set'}
                </p>
              )}
          </Row>
          
        </Container>
      </Jumbotron>

      {content[index].audio && (
        <Audio src={content[index].audio} controls style={{ width: '100%' }} />
      )}

      {buildTranslations()}

        <Container className="p-5 story">
        {content[index].story_text ? (
          ReactHtmlParser(content[index].story_text)
        ) : (
          <h1 style={{ textAlign: 'center' }}>No story content yet :(</h1>
          )}
      </Container>
    </>
  );
};

export default View;
