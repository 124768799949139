import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Editor from '../Editor';

import Details from '../Details';

import Audio from '../Audio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit as Edit,
  faHeadphones as Headphones,
  faInfoCircle as DetailsIcon
} from '@fortawesome/free-solid-svg-icons';

const Translations = ({ state, setState, token, index, language, org, user, type }) => {
  const [key, setKey] = useState('details');

  return (
    <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
      <Tab
        eventKey="details"
        title={
          <>
            <FontAwesomeIcon icon={DetailsIcon} className="mr-1" />
            Details
          </>
        }
      >
        <Details index={index} language={language} state={state} setState={setState} />
      </Tab>

      <Tab
        eventKey="editor"
        title={
          <>
            <FontAwesomeIcon icon={Edit} className="mr-1" />
            Editor
          </>
        }
      >
        <Editor
          index={index}
          language={language}
          state={state}
          token={token}
          setState={setState}
          org={org}
          user={user}
        />
      </Tab>

      <Tab
        eventKey="audio"
        title={
          <>
            <FontAwesomeIcon icon={Headphones} className="mr-1" />
            Audio
          </>
        }
      >
        <Audio
          state={state}
          index={index}
          language={language}
          token={token}
          setState={setState}
          org={org}
          user={user}
          type={type}
        />
      </Tab>
    </Tabs>
  );
};

export default Translations;
