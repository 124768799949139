import React, { useState } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import ChangePasswordView from './view';
import { Eye, EyeSlash } from '../../../Assets';
import tracker from '../../../HOC/Tracker';

tracker();

const ChangePassword = ({ computedMatch, token }) => {
  const SCHEMA = yup.object().shape({
    password: yup
      .string()
      .required('*Password is required.')
      .min(8, '*Password must be between 8-15 characters.')
      .max(15, '*Password must be between 8-15 characters.')
      .matches(/\d/, '*Password must contain at least one number.')
      .matches(/[a-z]/, '*Password must contain at least one lowercase letter.')
      .matches(/[A-Z]/, '*Password must contain at least one uppercase letter.'),
    check: yup
      .string()
      .required('*You must confirm your password.')
      .oneOf([yup.ref('password'), null], "*Passwords don't match.")
  });

  const INITIAL_STATE = {
    password: '',
    check: '',
    eye: Eye,
    confirmEye: Eye,
    type: 'password',
    confirmType: 'password'
  };

  const HEADERS = {
    headers: { Authorization: token }
  };

  const [state, setState] = useState(INITIAL_STATE);

  const handleSuccess = () =>
    setState((previousState) => ({
      ...previousState,
      msg: 'Password updated successfully!',
      variant: 'success',
      show: true
    }));

  const handleError = ({ response }) =>
    setState((previousState) => ({
      ...previousState,
      msg: response.status === 422 && response.data[0].msg,
      variant: 'danger',
      show: true
    }));

  const handleSubmit = (input) => {
    const DATA = {
      password: input.password
    };

    axios
      .put(`/user/${computedMatch.params.username}`, DATA, HEADERS)
      .then(handleSuccess)
      .catch(handleError);
  };

  const toggleEye = () =>
    setState((previousState) => ({
      ...previousState,
      eye: state.eye === Eye ? EyeSlash : Eye,
      type: state.type === 'password' ? 'text' : 'password'
    }));

  const toggleConfirmEye = () =>
    setState((previousState) => ({
      ...previousState,
      confirmEye: state.confirmEye === Eye ? EyeSlash : Eye,
      confirmType: state.confirmType === 'password' ? 'text' : 'password'
    }));

  return (
    <ChangePasswordView
      state={state}
      schema={SCHEMA}
      handleSubmit={handleSubmit}
      user={computedMatch.params.username}
      toggleEye={toggleEye}
      toggleConfirmEye={toggleConfirmEye}
    />
  );
};

export default ChangePassword;
