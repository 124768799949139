import { Helmet } from 'react-helmet';

const SEO = ({ title, description, url }) => (
    <Helmet>
        <title>NorthWord | {title}</title>
        <meta name='description' content={description} />
        <link rel='canonical' href={url} />
    </Helmet>
)

export default SEO;