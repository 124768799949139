import React from 'react';
import { Jumbotron, Container, Button, Form, Alert, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt as Icon } from '@fortawesome/free-solid-svg-icons';
import { SEO } from '../../Components';
import tracker from '../../HOC/Tracker';

tracker();

const Login = ({
  handleSubmit,
  handleChange,
  handleClick,
  setState,
  eye,
  message,
  show,
  variant,
  type
}) => (
  <>
    <SEO title='Login' description="Log in to NorthWord" url='https://www.northwordproject.com/login' />
  <Container fluid>
    <Jumbotron variant="secondary" className="m-5">
      <h1>Login</h1>

      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicUsername">
          <Form.Label>Username</Form.Label>
          <Form.Control
            required
            name="username"
            onChange={handleChange}
            placeholder="Enter username"
            maxLength="15"
          />
          <Form.Control.Feedback type="invalid">Please choose a username.</Form.Control.Feedback>
        </Form.Group>

        <Form.Label>Password</Form.Label>
        <InputGroup className="mb-2" controlId="formBasicPassword">
          <Form.Control
            name="password"
            onChange={handleChange}
            type={type}
            placeholder="Password"
            maxLength="15"
          />
          <Button as={InputGroup.Append} variant="primary" id="show" onClick={handleClick}>
            <img src={eye} alt="Show password" />
          </Button>
        </InputGroup>

        <Alert show={show} variant={variant}>
          {message}
        </Alert>

        <Button variant="primary" type="submit">
          <FontAwesomeIcon icon={Icon} className="mr-1" />
          Login
        </Button>
      </Form>
    </Jumbotron>
    </Container>
    </>
);

export default Login;
