import React from 'react';
import { Jumbotron, Container, Button, Form, Alert, Row, Col } from 'react-bootstrap';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload as Upload } from '@fortawesome/free-solid-svg-icons';

export default ({ handleSubmit, handleChange, variant, message, show, file, header }) => {
  const renderPreview = () => {
    if (file && file.length > 0) {
      return <img src={URL.createObjectURL(file[0])} className="img-thumbnail banner-preview" />;
    } else if (header) {
      return <img src={header} className="img-thumbnail banner-preview" />;
    }
  };

  return (
    <Container className="row m-0 p-0" fluid>
      <Jumbotron className="my-lg-5 my-0 h-sm-100 mx-lg-auto mx-0 w-lg-75 w-100">
        <h1>Upload Banner Image</h1>

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formHero">
            <Form.File id="hero-image" onChange={handleChange} accept="image/*" />
            <Form.Text muted>
              Required. Please choose a high quality landscape image to be used as the banner image.
              <br />
              Suggested sizes include: 1920 x 1080, 2560 x 1440 & 2880 x 1800.
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Button variant="primary" type="submit">
              <FontAwesomeIcon icon={Upload} className="mr-1" />
              Upload
            </Button>

            <Alert show={show} variant={variant}>
              {message}
            </Alert>
          </Form.Group>

          <Form.Group controlId="bannerPreview">{renderPreview()}</Form.Group>
        </Form>
      </Jumbotron>
    </Container>
  );
};
