import React from 'react';
import './style.scss';
import Deck from './Deck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser as User } from '@fortawesome/free-solid-svg-icons';
import { Confirm, Loader, SEO } from '../../../Components/';

import groupBy from 'lodash/groupBy';

export default ({ users, state, handleShow, handleDelete, handleCancel }) => {
  const sortUsers = () => {
    const groupedByOrganisation = groupBy(users, 'organisation');

    let decks = [];
    for (const [key, value] of Object.entries(groupedByOrganisation)) {
      decks.push(
        <Deck
          users={value}
          handleShow={handleShow}
          handleCancel={handleCancel}
          organisation={
            <>
              <FontAwesomeIcon icon={User} className="mr-2" />
              {key}
            </>
          }
        />
      );
    }
    return decks;
  };

  return (
    <>
      <SEO title='Manage Users' description='Manage users of the NorthWord platform.' url='https://www.northwordproject.com/users' />
      {state.loading ? (
        <Loader style={state.visibility} />
      ) : (
        <>
          {sortUsers()}

          <Confirm
            show={state.show}
            handleCancel={handleCancel}
            msg="Once the user is deleted, their account cannot be recovered. However, their stories will remain."
            confirmText="Yes, Delete"
            confirmFunction={handleDelete}
          />
        </>
      )}
    </>
  );
};
