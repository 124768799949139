import React, { useEffect } from 'react'
import ReactGA from 'react-ga';

const tracker = () => {
    const TRACKING_ID = "UA-252329070-1"; 
    ReactGA.initialize(TRACKING_ID);

    ReactGA.pageview(window.location.pathname);
}

export default tracker;

