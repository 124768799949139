import React from 'react';
import Sidebar from 'react-sidebar';
import { Container, Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter as Filter, faTimes as Close } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import './style.scss';

const MySidebar = ({
  categories,
  languages,
  countries,
  show,
  handleShow,
  handleClose,
  filter,
  term,
  handleCategoryChange,
  handleLanguageChange,
  handleCountryChange,
  handleDistanceChange,
  handleSearch,
  handleTermChange,
  clear,
  search,
  type
}) => {
  const prepareCountryOptions = countries.map((country) => ({
                  label: country.name,
                  value: country.name
  })).sort((a, b) => a.value.localeCompare(b.value));

  const prepareCountryValues = filter.countries.map((country) => ({
                  label: country,
                  value: country
                }))
  
  const prepareLanguageOptions = languages.map((language) => ({
              label: language,
              value: language
  })).sort((a, b) => a.value.localeCompare(b.value));

  const prepareLanguageValues = filter.languages.map((language) => ({
    label: language,
    value: languages
  }));
  
  const prepareCategoryOptions = categories.map((category) => ({
              label: category,
              value: category
  })).sort((a, b) => a.value.localeCompare(b.value));
  
  const prepareCategoryValues = filter.categories.map((category) => ({
              label: category,
              value: category
  }))
  
  const content = (
    <Container>
      <h2>
        Refine Results
        <FontAwesomeIcon className="close" icon={Close} onClick={handleClose} />
      </h2>

      <Form>
        <Form.Group controlId="categoriesForm">
          <Form.Label>Categories</Form.Label>
          <Form.Control
            as={Select}
            name="categories"
            custom
            onChange={handleCategoryChange}
            options={prepareCategoryOptions}
            value={prepareCategoryValues}
            isMulti
            placeholder="Select categories..."
            classNamePrefix="select"
          />
        </Form.Group>

        <Form.Group controlId="languagesForm">
          <Form.Label>Languages</Form.Label>
          <Form.Control
            as={Select}
            name="languages"
            custom
            onChange={handleLanguageChange}
            options={prepareLanguageOptions}
            value={prepareLanguageValues}
            isMulti
            placeholder="Select languages..."
            classNamePrefix="select"
          />
        </Form.Group>

        {type === 'Telling Stories' && (
          <>
            <Form.Group controlId="countriesForm">
              <Form.Label>Countries</Form.Label>
              <Form.Control
                as={Select}
                name="countries"
                custom
                onChange={handleCountryChange}
                options={prepareCountryOptions}
                value={prepareCountryValues}
                isMulti
                placeholder="Select countries..."
                classNamePrefix="select"
              />
            </Form.Group>

            {type === 'Telling Stories' && (<Form.Group>
              <Form.Label>Distance</Form.Label>
              <InputGroup>
                <input
                  type="range"
                  id="distance-slider"
                  className="w-75 mr-2"
                  min={10}
                  max={2000}
                  defaultValue={2000}
                  onChange={handleDistanceChange}
                />
                {filter.distance} miles
              </InputGroup>
            </Form.Group>)}
          </>
        )}

        <Form.Group controlId="searchForm">
          <Form.Label>Search</Form.Label>
          <InputGroup>
            <Form.Control
              name="search"
              placeholder={`Search for a story...`}
              maxLength="25"
              onChange={handleTermChange}
              value={term}
            />
            <Button onClick={handleSearch}>Search</Button>
            <FontAwesomeIcon className="close my-auto ml-1" icon={Close} onClick={clear} />
          </InputGroup>
          <Form.Text className="text-muted">{search && `Showing results for: "${term}"`}</Form.Text>
        </Form.Group>
      </Form>
    </Container>
  );

  return (
    <Sidebar sidebar={content} open={show} onSetOpen={handleShow} sidebarClassName="sidebar">
      <Button className="ml-3 my-3" variant="tertiary" onClick={handleShow}>
        <FontAwesomeIcon icon={Filter} className="mr-1" />
        Refine
      </Button>
    </Sidebar>
  );
};

export default MySidebar;