import React from 'react';
import { Formik } from 'formik';
import {
  Jumbotron,
  Container,
  Button,
  Form,
  Alert,
  DropdownButton,
  Dropdown
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers as Organisations, faUserEdit as Edit } from '@fortawesome/free-solid-svg-icons';
import { SEO } from '../../../Components';

const EditUser = ({ schema, state, handleSubmit, handleSelect, handleCheck, user }) => (
  <>
    <SEO title='Edit User' description="Change the details of a user's account" url='https://www.northwordproject.com/users/edit' />
  <Container className="row m-0 p-0" fluid>
    <Jumbotron className="my-lg-5 my-0 h-sm-100 mx-lg-auto mx-0 w-lg-75 w-100">
      <h1>Change Details</h1>
      <h4>{state.username}</h4>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          username: state.username,
          organisation: state.organisation || state.organisations[0],
          role: state.role
        }}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          values,
          touched,
          isValid,
          errors
        }) => (
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control
                name="username"
                onChange={handleChange}
                placeholder="Enter a username..."
                maxLength="15"
                value={values.username}
                isInvalid={touched.username && !!errors.username}
              />
              <Form.Text muted>Change the username.</Form.Text>
              <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicOrganisation">
              <Form.Label>Organisation</Form.Label>
              <Form.Control
                name="organisation"
                onSelect={(e) => handleSelect(e, setFieldValue)}
                value={values.organisation}
                isInvalid={touched.organisation && !!errors.organisation}
                as={DropdownButton}
                title={
                  <>
                    <FontAwesomeIcon icon={Organisations} className="mr-1" />
                    {values.organisation ?? Organisations}
                  </>
                }
                custom
              >
                {state.organisations.map((org) => (
                  <Dropdown.Item eventKey={org}>{org}</Dropdown.Item>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.organisation}</Form.Control.Feedback>
              <Form.Text muted>Change the user's organisation.</Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicRole">
              <Form.Label>Is the user an admin?</Form.Label>
              <Form.Check
                type="switch"
                id="admin-switch"
                checked={state.role === 'ADMIN'}
                label={state.role === 'ADMIN' ? 'Yes' : 'No'}
                name="role"
                value={values.role}
                onChange={handleCheck}
                isInvalid={!!errors.role}
              />
              <Form.Control.Feedback type="invalid">{errors.role}</Form.Control.Feedback>
            </Form.Group>

            <Alert show={state.show} variant={state.variant}>
              {state.msg}
            </Alert>

            <Button variant="primary" type="submit">
              <FontAwesomeIcon icon={Edit} className="mr-1" />
              Update
            </Button>
          </Form>
        )}
      </Formik>
    </Jumbotron>
    </Container>
    </>
);

export default EditUser;
