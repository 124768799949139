import React from 'react';
import {
  Container,
  Button,
  Form,
  Alert,
  InputGroup,
  DropdownButton,
  Dropdown
} from 'react-bootstrap';
import { Loader, SEO } from '../../../Components';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserPlus as Register,
  faUsers as Organisations
} from '@fortawesome/free-solid-svg-icons';

const RegisterView = ({
  schema,
  state,
  handleSubmit,
  handleSelect,
  handleCheck,
  toggleEye,
  toggleConfirmEye
}) =>
  state.loading ? (
    <Loader />
  ) : (
      <>
        <SEO title='Register' description='Register a user for the NorthWord platform.' url='https://www.northwordproject.com/register' />
    <Container className="m-0 p-5 h-100 mx-lg-auto mx-0 w-lg-75 w-100 register" fluid>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          username: '',
          organisation: state.organisations[0] || '',
          password: '',
          confirm: '',
          admin: false,
          check: ''
        }}
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
          values,
          touched,
          isValid,
          errors
        }) => (
          <Form onSubmit={(e) => handleSubmit(e, isValid)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Username</Form.Label>
              <Form.Control
                name="username"
                onChange={handleChange}
                placeholder="Enter a username..."
                maxLength="15"
                value={values.username}
                isInvalid={touched.username && !!errors.username}
              />
              <Form.Text muted>
                Required. Enter a username that the user will use to access the system.
              </Form.Text>
              <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicOrganisation">
              <Form.Label>Organisation</Form.Label>
              <Form.Control
                name="organisation"
                value={values.organisation}
                isInvalid={touched.organisation && !!errors.organisation}
                as={DropdownButton}
                onSelect={(e) => handleSelect(e, setFieldValue)}
                title={
                  <>
                    <FontAwesomeIcon icon={Organisations} className="mr-1" />
                    {values.organisation ?? Organisations}
                  </>
                }
                custom
              >
                {state.organisations.map((org) => (
                  <Dropdown.Item eventKey={org}>{org}</Dropdown.Item>
                ))}
              </Form.Control>

              <Form.Control.Feedback type="invalid">{errors.organisation}</Form.Control.Feedback>
              <Form.Text muted>Required. What organisation does the user belong to?</Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control
                  name="password"
                  onChange={handleChange}
                  type={state.type}
                  placeholder="Enter a password..."
                  maxLength="15"
                  value={values.password}
                  isInvalid={touched.password && !!errors.password}
                />
                <Button as={InputGroup.Append} variant="primary" id="show" onClick={toggleEye}>
                  <img src={state.eye} alt="Show password" />
                </Button>
                <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
              </InputGroup>

              <Form.Text muted>Required. Enter a strong password.</Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicPasswordCheck">
              <Form.Label>Confirm Password</Form.Label>
              <InputGroup>
                <Form.Control
                  name="check"
                  type={state.confirmType}
                  onChange={handleChange}
                  placeholder="Enter your password again..."
                  maxLength="15"
                  value={values.check}
                  isInvalid={touched.check && !!errors.check}
                />
                <Button
                  as={InputGroup.Append}
                  variant="primary"
                  id="show"
                  onClick={toggleConfirmEye}
                >
                  <img src={state.confirmEye} alt="Show password confirm" />
                </Button>
                <Form.Control.Feedback type="invalid">{errors.check}</Form.Control.Feedback>
              </InputGroup>

              <Form.Text muted>Required. Enter the password again.</Form.Text>
            </Form.Group>

            <Form.Group controlId="formBasicRole">
              <Form.Label>Is the user an admin?</Form.Label>
              <Form.Check
                type="switch"
                id="admin-switch"
                label={state.role === 'ADMIN' ? 'Yes' : 'No'}
                onChange={handleCheck}
                name="role"
                value={values.role}
                isInvalid={!!errors.role}
              />
              <Form.Control.Feedback type="invalid">{errors.role}</Form.Control.Feedback>
              <Form.Text muted>Required.</Form.Text>
            </Form.Group>

            <Alert show={state.show} variant={state.variant}>
              {state.msg}
            </Alert>

            <Button variant="primary" type="submit">
              <FontAwesomeIcon icon={Register} className="mr-1" /> Register
            </Button>
          </Form>
        )}
      </Formik>
        </Container>
        </>
  );

export default RegisterView;
