import React from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';

const Confirm = ({ show, error, msg, handleCancel, confirmText, confirmFunction }) => (
  <Modal show={show} onHide={handleCancel} backdrop="static" keyboard={false}>
    <Modal.Header closeButton>
      <Modal.Title>Are you sure?</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {msg}
      <Alert show={!!error} variant="danger">
        {error}
      </Alert>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>
      <Button variant="primary" onClick={confirmFunction}>
        {confirmText}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default Confirm;
