import React from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit as Edit,
  faUndo as Retract,
  faTrash as Delete,
  faShare as Publish,
  faGlasses as View
} from '@fortawesome/free-solid-svg-icons';

const Buttons = ({ story, handleShowPublish, handleShowRetract, handleShowDelete }) => {
  const renderPublish = () => {
    return story.released ? (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={renderTooltip('Retract')}
      >
        <Button variant="primary" onClick={(e) => handleShowRetract(e, story.id)}>
          <FontAwesomeIcon icon={Retract} />
        </Button>
      </OverlayTrigger>
    ) : (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={renderTooltip('Publish')}
      >
        <Button variant="primary" onClick={(e) => handleShowPublish(e, story.id)}>
          <FontAwesomeIcon icon={Publish} />
        </Button>
      </OverlayTrigger>
    );
  };

  const renderTooltip = (name) => (
    <Tooltip id="tooltip-top" variant="tertiary">
      {name}
    </Tooltip>
  );

  return (
    <div className="d-flex justify-content-center">
      <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={renderTooltip('View')}>
        <Button variant="primary" as={Link} to={'/stories/' + story.id} key={story.id}>
          <FontAwesomeIcon icon={View} />
        </Button>
      </OverlayTrigger>

      {useLocation().pathname === '/stories/manage' && (
        <>
          {renderPublish()}
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={renderTooltip('Edit')}
          >
            <Button variant="primary" as={Link} to={'/editor/' + story.id}>
              <FontAwesomeIcon icon={Edit} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={renderTooltip('Delete')}
          >
            <Button variant="primary" onClick={(e) => handleShowDelete(e, story.id)}>
              <FontAwesomeIcon icon={Delete} />
            </Button>
          </OverlayTrigger>
        </>
      )}
    </div>
  );
};

export default Buttons;
