import React from 'react';
import '../style.scss';
import Deck from './Deck';
import { Confirm, Loader, Error, SEO } from '../../../Components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilRuler as Draft,
  faSpellCheck as Submitted,
  faShare as Published,
  faBook as Our
} from '@fortawesome/free-solid-svg-icons';

const OurStories = ({
  state,
  handleShowSubmit,
  handleShowWithdraw,
  handleShowDelete,
  handleSubmit,
  handleDelete,
  handleFix,
  handleCancelSubmit,
  handleCancelWithdraw,
  handleCancelDelete,
  handleCancelFix
}) => (
    <>
    <SEO title='Our Stories' description="View, edit, submit, withdraw and delete your organisation's stories." url='https://www.northwordproject.com/stories/our'/>
    {state.loading ? (
      <Loader style={state.visibility} />
    ) : (
      <>
        <Deck
          stories={state.draft}
          handleShowSubmit={handleShowSubmit}
          handleShowWithdraw={handleShowWithdraw}
          handleShowDelete={handleShowDelete}
          name={
            <>
              <FontAwesomeIcon icon={Draft} className="mr-2" />
              Draft
            </>
          }
        />

        <Deck
          stories={state.submitted}
          handleShowSubmit={handleShowSubmit}
          handleShowWithdraw={handleShowWithdraw}
          handleShowDelete={handleShowDelete}
          name={
            <>
              <FontAwesomeIcon icon={Submitted} className="mr-2" />
              Being Reviewed
            </>
          }
        />

        <Deck
          stories={state.published}
          handleShowSubmit={handleShowSubmit}
          handleShowWithdraw={handleShowWithdraw}
          handleShowDelete={handleShowDelete}
          name={
            <>
              <FontAwesomeIcon icon={Published} className="mr-2" />
              Published
            </>
          }
        />

        <Deck
          stories={state.our.sort((a, b) => a.content[0].title.localeCompare(b.content[0].title))}
          handleShowSubmit={handleShowSubmit}
          handleShowWithdraw={handleShowWithdraw}
          handleShowDelete={handleShowDelete}
          name={
            <>
              <FontAwesomeIcon icon={Our} className="mr-2" />
              Our Stories
            </>
          }
        />

        <Confirm
          show={state.showSubmit}
          handleCancel={handleCancelSubmit}
          msg="Are you sure that the story is ready for review?"
          confirmText="Yes, Submit"
          confirmFunction={(e) => handleSubmit(e, true)}
        />

        <Confirm
          show={state.showWithdraw}
          handleCancel={handleCancelWithdraw}
          msg="Are you sure that you want to withdraw the story? You will need to submit the story again for it to be reviewed before publishing."
          confirmText="Yes, Withdraw"
          confirmFunction={(e) => handleSubmit(e, false)}
        />

        <Confirm
          show={state.showDelete}
          handleCancel={handleCancelDelete}
          msg="Once the story is deleted, it cannot be recovered."
          confirmText="Yes, Delete"
          confirmFunction={handleDelete}
        />

        <Error
          show={state.showFix}
          handleCancel={handleCancelFix}
          msg="Cannot submit story as required information is missing. Would you like to go to the editor to fix this?"
          errors={state.errors}
          confirmText="Yes, Edit"
          confirmFunction={handleFix}
        />
      </>
    )}
  </>
);

export default OurStories;
