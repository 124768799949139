import React from 'react';
import './style.scss';

const Loader = ({ style }) => {
  const OVERLAY_STYLE = {
    position: 'fixed',
    display: 'block',
    visibility: 'hidden',
    width: '100%',
    height: '90vh',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 5
  };

  return (
    <div style={{ ...OVERLAY_STYLE, visibility: style }}>
      <div className="spinner">
        <div className="double-bounce1" />
        <div className="double-bounce2" />
      </div>
    </div>
  );
};

export default Loader;
