import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import DeckStories from './view';
import SCHEMA from '../../../Validation/story.schema';
import tracker from '../../../HOC/Tracker';

tracker();

const ManageStores = ({ token }) => {
  const INITIAL_STORY = {
    released: [],
    submitted: [],
    all: [],
    showPublish: false,
    showRetract: false,
    showDelete: false,
    showFix: false,
    id: null,
    loading: false,
    errors: []
  };

  const [state, setState] = useState(INITIAL_STORY);

  const history = useHistory();

  const OPTIONS = {
    headers: { Authorization: token }
  };

  const sortStories = (stories) => ({
    released: stories.filter((story) => story.released && story.submitted),
    submitted: stories.filter((story) => !story.released && story.submitted),
    all: stories.filter((story) => story.submitted)
  });

  const handleCancelPublish = () =>
    setState((previousState) => ({ ...previousState, showPublish: false }));
  const handleCancelRetract = () =>
    setState((previousState) => ({ ...previousState, showRetract: false }));
  const handleCancelDelete = () =>
    setState((previousState) => ({ ...previousState, showDelete: false }));
  const handleCancelFix = () => setState((previousState) => ({ ...previousState, showFix: false }));

  const handlePublish = (e, released) => {
    try {
      const DATA = {
      released: released
    };

    const STORIES = togglePublish(state.all, released);
    const SORTED_STORIES = sortStories(STORIES);

    axios.put('/story/' + state.id, DATA, OPTIONS).then(() =>
      setState((previousState) => ({
        ...previousState,
        ...SORTED_STORIES,
        showPublish: false,
        showRetract: false
      }))
    )
    } catch (e) {
      console.log(e)
    }
    
  };

  const validate = (e, id) =>
    axios.get(`/story/${id}`).then(({ data }) => {
      SCHEMA()
        .then((res) => res.validate(data[0], { abortEarly: false }))
        .then(() => handleShowPublish(id))
        .catch(({ errors }) =>
          console.log(errors)
        );
    });

  const getStories = () => {
    setState((previousState) => ({ ...previousState, loading: true }));

    axios
      .get(`/story?submitted=true`)
      .then(({ data }) =>
        setState((previousState) => ({ ...previousState, ...sortStories(data), loading: false }))
      );
  };

  useEffect(getStories, []);

  const togglePublish = (stories, released) => {
    const STORY_INDEX = stories.findIndex((story) => story.id === state.id);

    if (STORY_INDEX >= 0) stories[STORY_INDEX].released = released;

    return stories;
  };

  const handleDelete = () =>
    axios.delete('/story/' + state.id, OPTIONS).then(() => {
      const STORIES = state.all.filter((story) => story.id !== state.id);
      setState((previousState) => ({
        ...previousState,
        ...sortStories(STORIES),
        showDelete: false
      }));
    });

  const handleFix = () => {
    history.push(`/editor/${state.id}`);
  };

  const handleShowPublish = (id) =>
    setState((previousState) => ({ ...previousState, id: id, showPublish: true }));

  const handleShowRetract = (e, id) =>
    setState((previousState) => ({ ...previousState, id: id, showRetract: true }));

  const handleShowDelete = (e, id) =>
    setState((previousState) => ({ ...previousState, id: id, showDelete: true }));

  return (
    <DeckStories
      state={state}
      handlePublish={handlePublish}
      handleDelete={handleDelete}
      handleFix={handleFix}
      handleShowPublish={validate}
      handleShowRetract={handleShowRetract}
      handleShowDelete={handleShowDelete}
      handleCancelPublish={handleCancelPublish}
      handleCancelRetract={handleCancelRetract}
      handleCancelDelete={handleCancelDelete}
      handleCancelFix={handleCancelFix}
    />
  );
};

export default ManageStores;
