import React from 'react';
import Deck from './Deck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMountain as Mountain,
  faThumbsUp as Thumb
} from '@fortawesome/free-solid-svg-icons';

const SkillsForStoriesDeck = ({ stories, type }) => {
  
  const ALPHABETICAL = stories.sort((a, b) =>
    a.content[0].title.localeCompare(b.content[0].title)
  );

  const RECOMMENDED = [...stories].sort((a, b) => b.views - a.views).slice(0, 10);

  return (
    <>
      <Deck
        stories={ALPHABETICAL}
        name={
          <>
            <FontAwesomeIcon icon={Mountain} className="mr-1" />
            All Making Stories
          </>
        }
        type={type}
      />

      <Deck
        stories={RECOMMENDED}
        name={
          <>
            <FontAwesomeIcon icon={Thumb} className="mr-1" />
            Most Viewed Making Stories
          </>
        }
        type={type}
      />
    </>
  );
};

export default SkillsForStoriesDeck;
