import React from 'react';
import { Jumbotron, Container, Form } from 'react-bootstrap';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { Formik } from 'formik';

const DetailsView = ({
  handleBlur,
  handleBlur2,
  handleChange,
  handleKeywordChange,
  handleCategoryChange,
  options,
  categories,
  keywords,
  story,
  language,
  index,
  schema
}) => (
  <Container className="row m-0 p-0" fluid>
    <Jumbotron className="my-lg-5 my-0 h-sm-100 mx-lg-auto mx-0 w-lg-75 w-100">
      <h1>Details</h1>
      <Formik
        validationSchema={schema}
        initialValues={{
          title: story.content[index].title,
          subtitle: story.content[index].subtitle,
          summary: story.content[index].summary,
          author: story.author,
          categories: categories,
          keywords: keywords
        }}
      >
        {({
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          validateField
        }) => (
          <Form>
            <Form.Group controlId="formBasicTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                name="title"
                onChange={(e) => handleChange(e, setFieldValue)}
                onBlur={(e) => handleBlur(e, setFieldTouched, setFieldError, validateField)}
                placeholder="Enter the name of the story..."
                required
                maxLength="50"
                value={values.title}
                isInvalid={touched.title && !!errors.title}
              />
              <Form.Text muted>Required. Give your story a title.</Form.Text>
              <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicSubtitle">
              <Form.Label>Subtitle</Form.Label>
              <Form.Control
                name="subtitle"
                value={values.subtitle}
                isInvalid={touched.subtitle && !!errors.subtitle}
                onChange={(e) => handleChange(e, setFieldValue)}
                onBlur={(e) => handleBlur(e, setFieldTouched, setFieldError, validateField)}
                placeholder="Enter a story subtitle..."
                maxLength="50"
              />
              <Form.Text muted>Optional. Extend your title with an interesting subtitle.</Form.Text>
              <Form.Control.Feedback type="invalid">{errors.subtitle}</Form.Control.Feedback>
            </Form.Group>
            {language === 'English' && (
              <>
                <Form.Group controlId="formBasicAuthor">
                  <Form.Label>Author</Form.Label>
                  <Form.Control
                    value={values.author}
                    isInvalid={touched.author && !!errors.author}
                    name="author"
                    onChange={(e) => handleChange(e, setFieldValue)}
                    onBlur={(e) => handleBlur(e, setFieldTouched, setFieldError, validateField)}
                    placeholder="Enter the author of the story..."
                    maxLength="30"
                  />
                  <Form.Text muted>
                    Optional. Who wrote your story? Multiple authors may be entered here.
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">{errors.author}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="categoriesForm">
                  <Form.Label>Categories</Form.Label>
                  <Form.Control
                    as={Select}
                    name="categories"
                    options={options}
                    custom
                    isMulti
                    placeholder="Select categories that describe your story..."
                    onChange={(e) => handleCategoryChange(e, setFieldValue)}
                    onBlur={(e) =>
                      handleBlur2('categories', setFieldTouched, setFieldError, validateField)
                    }
                    value={story.categories.map((category) => ({
                      label: category,
                      value: category
                    }))}
                    isInvalid={touched.categories && !!errors.categories}
                    classNamePrefix="select"
                  />
                  <Form.Text muted>
                    Required. Select at least one category that describes your story.
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">{errors.categories}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="keywordsForm">
                  <Form.Label>Keywords</Form.Label>
                  <Form.Control
                    custom
                    as={Creatable}
                    isMulti
                    name="keywords"
                    value={story.keywords.map((keyword) => ({
                      label: keyword,
                      value: keyword
                    }))}
                    onChange={(e) => handleKeywordChange(e, setFieldValue)}
                    onBlur={(e) =>
                      handleBlur2('keywords', setFieldTouched, setFieldError, validateField)
                    }
                    isInvalid={touched.keywords && !!errors.keywords}
                    classNamePrefix="select"
                    placeholder="Enter keywords to describe your story..."
                  />
                  <Form.Text muted>Required. Separate keywords by comma.</Form.Text>
                  <Form.Control.Feedback type="invalid">{errors.keywords}</Form.Control.Feedback>
                </Form.Group>
              </>
            )}

            <Form.Group controlId="summaryForm">
              <Form.Label>Summary</Form.Label>
              <Form.Control
                onChange={(e) => handleChange(e, setFieldValue)}
                onBlur={(e) => handleBlur(e, setFieldTouched, setFieldError, validateField)}
                name="summary"
                value={values.summary}
                isInvalid={touched.summary && !!errors.summary}
                as="textarea"
                rows={5}
                maxLength="250"
                placeholder="Describe the story in a short summary... (Max 250 Characters)"
              />
              <Form.Text muted>Required. Please enter a brief summary of the story.</Form.Text>
              <Form.Control.Feedback type="invalid">{errors.summary}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </Jumbotron>
  </Container>
);

export default DetailsView;
