import React from 'react';
import { SEO } from '../../Components'
import { Container, Row, Col } from 'react-bootstrap';
import { Audio as Microphone, Antiques as Objects, Camera, Finland as Landscape, Logo, LogoPlain, Npa, Bull, CausewayGuides, Lines} from '../../Assets';
import './style.scss';
import Carousel from '../Home/Carousel';
import { Link } from "react-router-dom";
import tracker from '../../HOC/Tracker';

tracker();

const Home = () => {

  return (
    <>
      <SEO title="About" description="Find out more about NorthWord." url='https://www.northwordproject.com/about' />

      <Container fluid className="about parallax lander d-flex align-items-center justify-content-center">
        <Row className='p-5'>
          <Col sm={1} md={5} className='mx-md-5 mx-sm-auto my-3'><img src={LogoPlain} alt="NorthWord" /></Col>
          <Col sm={1} md={5} className='mx-md-5 mx-sm-auto my-auto text-white align-middle text-justify'>Whether it is a story about a person or a place, an object or a legend, whether fact or a fiction, in English, Gaelic or in dialect, a fairytale or a ghost story, we want to hear your stories.
          </Col>
      </Row>
      </Container>
        
      
      <Container fluid className="bg-primary d-flex align-items-center justify-content-center">
        <Row className='p-md-5 p-sm-0' > 
                  <Col sm={10} md={6} className='mx-md-5 p-5 my-auto text-contrast-primary text-justify' ><p>Northword was born out of the StoryTagging project, an initiative that showed how stories can be used authentically and creatively to support small businesses working in remote locations.<br /><br />Organisations from Scotland, Northern Ireland, Sweden, Finland and Russia used workshops and local story gatherers to <Link to='/stories/telling' className="secondary-link"> collect and map over sixty stories</Link> celebrating the history, archaeology, traditions, folklore, landscape and environment of their area.</p></Col>
        <Col sm={6} md={4} className='mx-md-5 p-5 my-md-5 my-sm-0'><img className='img-fluid' src={Lines} alt="The Burghead Bull" /></Col>
      </Row>
      </Container>

      <Container fluid className="bg-contrast-primary d-flex align-items-center justify-content-center p-5 text-white text-justify">

               <p>Creative practitioners, such as artists, crafters and musicians, and other types of small businesses were invited to choose one of the stories to inspire a new creative work or marketing idea. As you can see <Link to='/stories/using' className="secondary-link">here</Link> the results varied from textiles to ceramics, traditional folk song to electronic music and soundscapes, a 10-part podcast to sculpture. Elsewhere, businesses in the tourism and service industries, such as a wellness centre and a museum about witches, were offered workshops and one-to-one guidance on how to use stories to market and promote their ventures. <Link to='/#documentary' className="secondary-link">This documentary</Link> follows some of the businesses as carried out this work.<br /><br />Another aim was to help rural businesses learn and develop digital marketing skills, and we have created <Link to='/stories/skills' className="secondary-link">this skills hub</Link> with useful resource</p>

      </Container>

      <Container fluid className="d-flex align-items-center justify-content-center bg-secondary">
        <Row className='p-md-5 p-sm-0'>
          <Col sm={6} md={4} className='mx-md-5 p-5 my-md-5 my-sm-0'><img className='img-fluid'  src={Lines} alt="The Causeway Guides" /></Col>
                  <Col sm={10} md={6} className='mx-md-5 p-5 my-auto text-white text-justify'>We hope the experience of the Northword SMEs will inspire other businesses to utilise the power of stories. The project has demonstrated that working with stories has the power to enhance a business, connect businesses with their communities, create a sense of place and inspire opportunities. If you are interested in learning how to use stories in your own ventures please use the <Link to='/stories/skills' className="secondary-link">skills hub</Link> or contact your nearest project partner.</Col>
        
      </Row>
      </Container>

  <Container fluid className='bg-tertiary m-0 p-3'><h2 className='text-center text-primary mt-3'>TYPES OF STORY</h2><p className='text-center text-primary mt-4'>
NorthWord is a place for stories of all shapes and sizes. Whether in written word or spoken aloud, fact or fiction - there is a story for everyone. 
</p></Container>
      <Container fluid className="bg-tertiary d-flex align-items-center justify-content-center mt-0">

        <Row xs={1} sm={1}  md={5} className='py-md-5 py-sm-0 m-sm-0 d-flex justify-content-center text-center'>
          <Col  className='m-3 bg-primary types-cards rounded'><h3 className='mt-3'>Spoken or Written</h3><hr /><img src={Microphone} alt='Microphone' className='story-types' /><p className='mt-3 lead'>Immerse yourself in the story by having it read to you by one of our creative partners and experience different dialects from around the North. </p></Col>
          <Col className='m-3 bg-primary  types-cards rounded'><h3 className='mt-3'>Pictures & Images</h3><hr /><img src={Camera} alt='Camera' className='story-types' /><p className='mt-3 lead'>A picture tells a thousand words! Some stories are accompanied by amazing pictures and photography. </p></Col>
          <Col className='m-3 bg-primary  types-cards rounded'><h3 className='mt-3'>Objects & Things</h3><hr /><img src={Objects} alt='Antique paintings' className='story-types' /><p className='mt-3 lead'>Uncover the heritage hidden in objects and learn why some things are treasured by their cultures. </p></Col>
          <Col className='m-3 bg-primary  types-cards rounded'><h3 className='mt-3'>Environment</h3><hr /><img src={Landscape} alt='Finnish forest' className='story-types' /><p className='mt-3 lead'>Northern Europe is home to many spectacular landscapes that have inspired some of our stories.</p></Col>
      </Row>
      </Container>

      <Container fluid className="d-flex align-items-center justify-content-center bg-primary">
        <Row className='p-md-5 p-sm-0'>
                  <Col className='mx-5 text-contrast-primary text-center my-3'>The StoryTagging project was part-funded by the European Union Northern Periphery and Arctic (NPA) Programme. It was led by RGU Orkney and included UHI West Highland, the Causeway Coast and Glens Heritage Trust, the University of Ulster, Kvarken Council, Region Västerbotten and Kenozero National Park. <br /> <br />

We regret that the events of 2022 halted work with our Russian partners at Kenozero National Park, where a wealth of stories had been collected and local artists had created many beautiful show pieces. <br /> <br /> Please click on the logos below to find out more about the partners.</Col>
          
          <Col className='mx-md-5 p-5 my-md-5 my-sm-0 '><Carousel /></Col>
          <Row id="npa" className="p-0 mx-5 mb-5 w-100">
<a id="npa-link" href="https://storytagging.interreg-npa.eu/">
          <img
            src={Npa}
              alt="Funded by the Northern Periphery and Arctic Programme and the European Regional Development Fund"
              
          />
</a>
      </Row>
          
      </Row>
      </Container>

      

      </>
  );
};

export default Home;
