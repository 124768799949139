import React from 'react';
import { Navbar, Nav, NavDropdown, Badge } from 'react-bootstrap';
import { Npa } from '../../Assets';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook as Book,
  faEllipsisH as Other,
  faUserCog as Admin,
  faTag as Tag,
  faSignOutAlt as Logout,
  faSignInAlt as Login,
  faUsers as Users,
  faCogs as Manage,
  faPlusSquare as Add,
  faLaughBeam as Our,
  faUserPlus as Register,
  faList as Categories,
  faLanguage as Language,
  faUserFriends as Organisations,
  faPen as Pen,
  faInfo as Info
} from '@fortawesome/free-solid-svg-icons';
import { Confirm } from '../../Components';
import './style.scss';

const PrivateNavBar = ({ username, logout, loggedIn, admin, expanded, handleClick, notifications, show, setShow, handleCancel }) => {
  const logoutAndHide = () => {
    logout()
    handleCancel()
  }
  const displayLoginButton = () =>
    loggedIn ? (
      <Nav.Link onClick={setShow}>
        <FontAwesomeIcon className="mr-1" icon={Logout} />
        Logout
      </Nav.Link>
    ) : (
      <Nav.Link onClick={() => handleClick(false)} as={Link} to="/login">
        <FontAwesomeIcon className="mr-1" icon={Login} />
        Login
      </Nav.Link>
    );

  const displayAdminButtons = () => {
    if(loggedIn){
    return admin ? (
      <NavDropdown
        title={
          <>
            <FontAwesomeIcon className="mr-1" icon={Admin} />
            Admin
          </>
        }
        id="admin-dropdown"
      >
        <NavDropdown.Item onClick={() => handleClick(false)} as={Link} to="/users">
          <FontAwesomeIcon className="mr-1" icon={Users} />
          Users
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => handleClick(false)} as={Link} to="/categories">
          <FontAwesomeIcon className="mr-1" icon={Categories} />
          Categories
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => handleClick(false)} as={Link} to="/languages">
          <FontAwesomeIcon className="mr-1" icon={Language} />
          Languages
        </NavDropdown.Item>

        <NavDropdown.Item onClick={() => handleClick(false)} as={Link} to="/organisations">
          <FontAwesomeIcon className="mr-1" icon={Organisations} /> Organisations
        </NavDropdown.Item>

        <NavDropdown.Item onClick={() => handleClick(false)} as={Link} to="/register">
          <FontAwesomeIcon className="mr-1" icon={Register} />
          Register
        </NavDropdown.Item>
      </NavDropdown>
    ) : (<Nav.Link  onClick={() => handleClick(false)} as={Link} to={`/users/changepassword/${username}`}>
        <FontAwesomeIcon className="mr-1" icon={Users} />
        Change Password
      </Nav.Link>)}}

  const displayStoryButtons = () =>
    loggedIn && (
      <NavDropdown
        title={
          <>
            <FontAwesomeIcon icon={Book} className="mr-1" /> Stories
          </>
        }
        id="stories-dropdown"
      >
        <NavDropdown.Item  onClick={() => handleClick(false)} as={Link} to="/stories/telling">
            <FontAwesomeIcon  icon={Book} /> Telling Stories

        </NavDropdown.Item>

         <NavDropdown.Item  onClick={() => handleClick(false)} as={Link} to="/stories/using">
            <FontAwesomeIcon  icon={Tag} /> Using Stories
        </NavDropdown.Item>

         <NavDropdown.Item  onClick={() => handleClick(false)} as={Link} to="/stories/skills">
            <FontAwesomeIcon  icon={Pen} /> Skills for Stories
        </NavDropdown.Item>

        {renderAdminStories()}

        <NavDropdown.Item onClick={() => handleClick(false)} href="/editor">
          <FontAwesomeIcon className="mr-2 " icon={Add} />
          New Story
        </NavDropdown.Item>
      </NavDropdown>
    );

  const renderAdminStories = () =>
    admin ? (
      <>
        <NavDropdown.Item onClick={() => handleClick(false)} as={Link} to="/stories/manage">
          <FontAwesomeIcon icon={Manage} /> Manage Stories
          {notifications > 0 && (
            <Badge className="ml-1" variant="info">
              {notifications}
            </Badge>
          )}
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => handleClick(false)} as={Link} to="/stories/our">
          <FontAwesomeIcon className="mr-2" icon={Our} />
          Organisation Stories
        </NavDropdown.Item>
      </>
    ) : (
      <>
        <NavDropdown.Item onClick={() => handleClick(false)} as={Link} to="/stories/our">
          <FontAwesomeIcon className="mr-2" icon={Our} />
          Organisation Stories
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => handleClick(false)} as={Link} to="/stories/other">
          <FontAwesomeIcon icon={Other} /> Other Stories
        </NavDropdown.Item>
      </>
    );

  return (<>
    <Navbar className="py-3" sticky="top" bg="light" expand="lg" expanded={expanded}>
      <Navbar.Brand to="/" as={Link}>
        <img
          alt="NorthWord logo"
          src="/logo.png"
          width="120"
          height="60"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
    <Navbar.Brand>
      <img
        alt="NPA"
        src={Npa}
        width="350"
        height="60"
        className="d-inline-block align-top"
      />
    </Navbar.Brand>
    

      <Navbar.Toggle onClick={() => handleClick(!expanded)} aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto mt-2">
          


          {displayStoryButtons()}

          {displayAdminButtons()}

          <Nav.Link as={Link} to="/about">
          <FontAwesomeIcon className="mr-1" icon={Info} />
          Our Story
        </Nav.Link>

          {displayLoginButton()}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <Confirm
      show={show}
      handleCancel={handleCancel}
      msg='Are you sure that you want to logout? This will log you out of all tabs and any unsaved work will be lost.'
      confirmText="Yes, Logout"
      confirmFunction={logoutAndHide}
    />
    </>
  );
};

export default PrivateNavBar;
