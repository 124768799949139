import React from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit as Edit,
  faUndo as Withdraw,
  faTrash as Delete,
  faShare as Submit,
  faGlasses as View
} from '@fortawesome/free-solid-svg-icons';

const Buttons = ({ story, handleShowSubmit, handleShowWithdraw, handleShowDelete }) => {
  const renderSubmit = () => {
    return story.submitted ? (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={renderTooltip('Withdraw')}
      >
        <Button variant="primary" onClick={(e) => handleShowWithdraw(e, story.id)}>
          <FontAwesomeIcon icon={Withdraw} />
        </Button>
      </OverlayTrigger>
    ) : (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={renderTooltip('Submit')}
      >
        <Button variant="primary" onClick={(e) => handleShowSubmit(e, story.id)}>
          <FontAwesomeIcon icon={Submit} />
        </Button>
      </OverlayTrigger>
    );
  };

  const renderTooltip = (name) => (
    <Tooltip id="tooltip-top" variant="tertiary">
      {name}
    </Tooltip>
  );

  return (
    <div className="d-flex justify-content-center">
      <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={renderTooltip('View')}>
        <Button variant="primary" as={Link} to={'/stories/' + story.id} key={story.id}>
          <FontAwesomeIcon icon={View} />
        </Button>
      </OverlayTrigger>

      {renderSubmit()}

      <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={renderTooltip('Edit')}>
        <Button variant="primary" as={Link} to={'/editor/' + story.id}>
          <FontAwesomeIcon icon={Edit} />
        </Button>
      </OverlayTrigger>

      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={renderTooltip('Delete')}
      >
        <Button variant="primary" onClick={(e) => handleShowDelete(e, story.id)}>
          <FontAwesomeIcon icon={Delete} />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default Buttons;
