import React, { memo } from 'react';
import { Sidebar } from '../../Components';
import { Container, Row, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt as MapIcon, faList as ListIcon } from '@fortawesome/free-solid-svg-icons';
import { SEO } from '../../Components';
import { TellingStoriesDeck, SkillsForStoriesDeck, UsingStoriesDeck} from './List';
import Map from './Map';

export default memo(
  ({
    stories,
    categories,
    location,
    languages,
    countries,
    show,
    handleShow,
    handleClose,
    handleToggle,
    handleSearch,
    handleCategoryChange,
    handleLanguageChange,
    handleCountryChange,
    handleDistanceChange,
    handleTermChange,
    term,
    filter,
    index,
    clear,
    type
  }) => {
    const getList = (type) => {
      switch (type) {
        case 'Telling Stories':
          return <TellingStoriesDeck stories={stories} type={type} />;
        case 'Skills for Stories':
          return <SkillsForStoriesDeck stories={stories} type={type}/>;
        case 'Using Stories':
          return <UsingStoriesDeck stories={stories} type={type}/>;
        default:
          return <TellingStoriesDeck stories={stories} type={type}/>;
      }
    }

   
    return (
      <>
        <SEO title={type || 'Explore the North'} description='Browse our collection of stories from across the North.' url='https://www.northwordproject.com/explore' />
        <Container fluid className="bg-white">
          <Row>
            <Sidebar
              categories={categories}
              languages={languages}
              countries={countries}
              show={show}
              handleShow={handleShow}
              handleCategoryChange={handleCategoryChange}
              handleLanguageChange={handleLanguageChange}
              handleCountryChange={handleCountryChange}
              handleDistanceChange={handleDistanceChange}
              handleSearch={handleSearch}
              handleTermChange={handleTermChange}
              handleClose={handleClose}
              filter={filter}
              term={term}
              clear={clear}
              type={type}
            />
          </Row>
        
          <Row>
          
            <ToggleButtonGroup
              type="radio"
              name="options"
              defaultValue={0}
              onChange={handleToggle}
              className="mx-auto my-3"
            >
              <ToggleButton
                id="tbg-radio-0"
                value={0}
                variant={index === 0 ? 'tertiary' : 'outline-tertiary'}
                style={{ visibility: type === "Telling Stories" ? 'visible' : 'hidden' }}
              >
                <FontAwesomeIcon icon={ListIcon} className="mr-1" /> List
              </ToggleButton>
              <ToggleButton
                id="tbg-radio-1"
                value={1}
                variant={index === 1 ? 'tertiary' : 'outline-tertiary'}
                style={{ visibility: type === "Telling Stories" ? 'visible' : 'hidden' }}
              >
                <FontAwesomeIcon icon={MapIcon} className="mr-1" />
                Map
              </ToggleButton>

              
            </ToggleButtonGroup>
            
            
          </Row>
        </Container>
        {index === 0 ? (
          getList(type)
        ) : (
          <Map stories={stories} location={location} />
        )}
      </>
    )
  }
)

