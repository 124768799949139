import React, { useState, useEffect } from 'react';
import axios from 'axios';
import View from './view.js';
import { Loader } from '../../Components';
import * as yup from 'yup';

const Details = ({ state, setState, language, index }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios.get('/admin/category').then(({ data }) => {
      const list = data
        .sort((a, b) => a.category.localeCompare(b.category))
        .map((category) => category.category);
      setCategories(list);
      setLoading(false);
    });
  }, []);

  const handleChange = ({ target: { name, value } }, setFieldValue) => {
    setFieldValue(name, value);
    const story = state.story;
    if (name === 'author') {
      story.author = value;
    } else {
      story.content[index][name] = value;
    }

    setState((previousState) => ({
      ...previousState,
      key: language,
      story: story
    }));
  };

  const handleBlur = (
    { target: { name, value } },
    setFieldTouched,
    setFieldError,
    validateField
  ) => {
    setFieldTouched(name, true);
    const error = validateField(name);
    setFieldError(name, error[name]);
  };

  const handleBlur2 = (name, setFieldTouched, setFieldError, validateField) => {
    setFieldTouched(name, true);
    const error = validateField(name);
    setFieldError(name, error[name]);
  };

  const handleKeywordChange = (newValue, setFieldValue) => {
    setFieldValue('keywords', newValue);
    setState((previousState) => ({
      ...previousState,
      story: {
        ...previousState.story,
        keywords: newValue.map((keyword) => keyword.value)
      }
    }));
  };

  const handleCategoryChange = (newValue, setFieldValue) => {
    setFieldValue('categories', newValue);
    setState((previousState) => ({
      ...previousState,
      story: {
        ...previousState.story,
        categories: newValue.map((category) => category.value)
      }
    }));
  };

  return loading ? (
    <Loader />
  ) : (
    <View
      handleBlur={handleBlur}
      handleBlur2={handleBlur2}
      handleChange={handleChange}
      handleKeywordChange={handleKeywordChange}
      handleCategoryChange={handleCategoryChange}
      options={categories.map((category) => ({
        label: category,
        value: category
      }))}
      categories={state.story.categories.map((category) => ({
        label: category,
        value: category
      }))}
      keywords={state.story.keywords.map((keyword) => ({
        label: keyword,
        value: keyword
      }))}
      story={state.story}
      language={language}
      index={index}
      schema={yup.object().shape({
        title: yup
          .string('*Title must be textual.')
          .test(
            'titlePresentAndCorrect',
            '*Title must be between 2-50 characters long.',
            (value) => value?.length >= 2 && value?.length <= 50
          ),
        subtitle: yup
          .string('*Subtitle must be textual.')
          .test(
            'subtitlePresentAndCorrect',
            '*Subtitle must be between 2-50 characters long.',
            (value) => value === '' || (value?.length >= 2 && value?.length <= 50)
          ),
        author: yup
          .string('*If used, author must be textual.')
          .test(
            'authorPresentAndCorrect',
            '*If used, author must be between 2-50 characters long.',
            (value) => value === '' || (value?.length >= 3 && value?.length <= 30)
          ),
        summary: yup
          .string('*Summary must be textual.')
          .test(
            'summaryPresentAndCorrect',
            '*Summary must be between 10-250 characters long.',
            (value) => value?.length >= 10 && value?.length <= 250
          ),
        categories: yup
          .array()
          .min(1, '*At least one category is required.')
          .of(
            yup
              .mixed('*Category must be from the list.')
              .test(
                'categoriesPresentAndCorrect',
                '*Categories must be chosen from the list.',
                (value) => categories.includes(value.value)
              )
          ),
        keywords: yup
          .array()
          .min(1, '*At least one keyword is required.')
          .of(
            yup
              .mixed('*Keyword must be textual.')
              .test(
                'keywordsPresentAndCorrect',
                '*Keyword must be between 3-30 characters long.',
                (value) => value?.value?.length >= 3 && value?.value?.length <= 30
              )
          )
      })}
    />
  );
};

export default Details;
