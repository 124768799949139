import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Npa } from '../../Assets';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook as Book, faInfo as Info, faPen as Pen, faTag as Tag } from '@fortawesome/free-solid-svg-icons';
import './style.scss';

const PublicNavBar = ({ expanded, handleClick }) => (
  <Navbar className="py-2" sticky="top" bg="light" expand="lg" expanded={expanded}>
    <Navbar.Brand to="/" as={Link}>
        <img
          alt="NorthWord logo"
          src="/logo.png"
          width="120"
          height="60"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
    <Navbar.Brand>
      <img
        alt="NPA"
        src={Npa}
        width="350"
        height="60"
        className="d-inline-block align-top"
      />
    </Navbar.Brand>
    

    <Navbar.Toggle onClick={() => handleClick(!expanded)} aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ml-auto mt-2">
         <Nav.Link as={Link} to="/stories/telling">
          <p>
            <FontAwesomeIcon  icon={Book} /> Telling Stories
          </p>
        </Nav.Link>

         <Nav.Link as={Link} to="/stories/using">
            <FontAwesomeIcon  icon={Tag} /> Using Stories
        </Nav.Link>

         <Nav.Link as={Link} to="/stories/skills">
            <FontAwesomeIcon  icon={Pen} /> Skills for Stories
        </Nav.Link>

        <Nav.Link as={Link} to="/about">
          <FontAwesomeIcon className="mr-1" icon={Info} />
          Our Story
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default PublicNavBar;
