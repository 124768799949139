import React, { useState, useEffect } from 'react';
import LanguagesView from './view';
import { Loader } from '../../Components';
import axios from 'axios';

const Languages = ({ createTab, setShow, state: parentState, setState: setParentState, type }) => {
  const [myState, setMyState] = useState({
    showError: false,
    variant: 'danger',
    language: '',
    languages: [],
    loading: false
  });

  useEffect(() => {
    setMyState((previousState) => ({ ...previousState, loading: true }));
    axios.get('/admin/language').then(({ data }) => {
      const list = data
        .sort((a, b) => a.language.localeCompare(b.language))
        .map((language) => ({
          value: language.language,
          label: language.language === 'English' ? 'English (Required)' : language.language,
          disabled: language.language === 'English'
        }));

      setMyState((previousState) => ({ ...previousState, languages: list, loading: false }));
    });
  }, []);

  const handleLanguageChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'select-option') {
      const VALUE = newValue[newValue.length - 1].value;
      addTab(newValue, VALUE);
    } else if (actionMeta.action === 'remove-value' || actionMeta.action === 'pop-value') {
      newValue.push(actionMeta.removedValue);
      setShow({
        show: true,
        language: actionMeta.removedValue.value
      });
    } else {
      newValue = newValue.concat(actionMeta.removedValues);
      setShow({
        show: true,
        language: actionMeta.removedValues
      });
    }
    setParentState((previousState) => ({ ...previousState, languages: newValue }));
  };

  const addTab = (newValue, value) => {
    if (value !== 'English') {
      setParentState((previousState) => ({
        ...previousState,
        languages: newValue
      }));

      createTab(value);
    }
  };

  return myState.loading ? (
    <Loader />
  ) : (
    <LanguagesView
      type={type}
      handleLanguageChange={handleLanguageChange}
      options={myState.languages}
      languages={parentState[type].content.map(
        (translation) =>
          translation.language !== 'English' && {
            value: translation.language,
            label: translation.language
          }
      )}
    />
  );
};

export default Languages;
