import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const Protected = (props) => {
  const { component: Component, ...rest } = props;
  const renderRoute = () => {
    if (props.loggedIn) return <Component {...rest} />;

    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location }
        }}
      />
    );
  };

  return <Route render={renderRoute} />;
};

export default Protected;
