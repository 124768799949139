import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Form, Container, Dropdown, DropdownButton } from 'react-bootstrap';
import './style.scss';
import { Formik } from 'formik';
import countries from "../../Utilities/countries";

const Places = ({ state, setState, setZoom, schema }) => {
  const [address, setAddress] = useState('');
  const handleChange = (address) => setAddress(address);

  const getCountry = (givenCountry) => {
    const country = countries.find((country) => country.code === givenCountry);

    return country?.name || "Invalid country";

  };
  const handleSelect2 = (e) => 
    setState(previousState => ({
      ...previousState,
      story: {
        ...previousState.story,
        country: getCountry(e),
      },
    }));



  
  const handleChange2 = ({ target: { name, value } }, setFieldValue) => {
    setFieldValue(name, value);
    const story = state.story;
    story[name] = value;

    setState((previousState) => ({
      ...previousState,
      story: story
    }));
  };

  const handleBlur = (
    { target: { name, value } },
    setFieldTouched,
    setFieldError,
    validateField
  ) => {
    setFieldTouched(name, true);
    const error = validateField(name);
    setFieldError(name, error[name]);
  };

  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address);

    let country = {};

    if (results[0]) {
      results[0].address_components.forEach((component) => {
        for (let b = 0; b < component.types.length; b++) {
          if (component.types[b] === 'country') {
            country = component;
            break;
          }
        }
      });
    }

    const latLng = await getLatLng(results[0]);

    setState((previousState) => ({
      ...previousState,
      story: {
        ...previousState.story,
        latitude: latLng.lat,
        longitude: latLng.lng,
        country: country.long_name
      }
    }));

    setZoom(16);
  };

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={{
          country: state.story.country,
          location_name: state.story.location_name
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          validateField
        }) => (
          <Form>
            <PlacesAutocomplete value={address} onChange={handleChange}>
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <Form.Group controlId="search-places">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    className="location-search-input"
                    name="place"
                    placeholder="Search for a place..."
                    {...getInputProps({
                      className: 'location-search-input'
                    })}
                  />
                  <Container
                    fluid
                    variant="secondary"
                    style={{
                      position: 'absolute',
                      zIndex: 2,
                      left: '0%'
                    }}
                    className="mx-0 px-0 location-search-input"
                  >
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';

                      const style = suggestion.active
                        ? {
                            backgroundColor: '#fafafa',
                            cursor: 'pointer'
                          }
                        : {
                            backgroundColor: '#ffffff',
                            cursor: 'pointer'
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style
                          })}
                        >
                          <span
                            className="mx-1"
                            onClick={() => handleSelect(suggestion.description)}
                          >
                            {suggestion.description}
                          </span>
                        </div>
                      );
                    })}
                  </Container>
                </Form.Group>
              )}
            </PlacesAutocomplete>

            <Form.Group controlId="country-input">
              <Form.Label>Country</Form.Label>
              <DropdownButton
                id="country-list"
                title={state.story.country || "Country"}
                className="mr-1"
                onSelect={(e) => handleSelect2(e)}
              >
                {countries.map((country) => (
                  <Dropdown.Item key={country.code} eventKey={country.code}>
                    {country.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <Form.Text muted>
                Required. Please enter which country the story is based in.
              </Form.Text>
              <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="location-input">
              <Form.Label>Location Name</Form.Label>
              <Form.Control
                name="location_name"
                value={values.location_name}
                isInvalid={touched.location_name && !!errors.location_name}
                placeholder="Enter a name for the location..."
                required
                maxLength="40"
                onChange={(e) => handleChange2(e, setFieldValue)}
                onBlur={(e) => handleBlur(e, setFieldTouched, setFieldError, validateField)}
              />
              <Form.Text muted>Required. Please enter a display name for the location.</Form.Text>
              <Form.Control.Feedback type="invalid">{errors.location_name}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Places;
