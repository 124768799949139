import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { Organisations as OrganisationsView, Loader } from '../../Components';
import tracker from '../../HOC/Tracker';

tracker();

const Organisations = () => {
  const SCHEMA = yup.object().shape({
    organisation: yup
      .string()
      .required('*Organisation is required')
      .min(3, '*Organisation must be between 3-15 characters')
      .max(40, '*Organisation must be between 3-40 characters')
  });

  const INITIAL_STATE = {
    organisation: '',
    selection: '',
    organisations: [],
    filtered: [],
    loading: false
  };

  const [state, setState] = useState(INITIAL_STATE);

  const handleSearch = (e) => {
    const ORGANISATIONS = state.organisations.filter((organisation) =>
      organisation.organisation.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setState((previousState) => ({ ...previousState, filtered: ORGANISATIONS }));
  };

  const handlePostSuccess = ({ data }) =>
    setState((previousState) => ({
      ...previousState,
      msg: 'Organisation added successfully!',
      variant: 'success',
      show: true,
      organisations: [...previousState.organisations, data]
    }));

  const handlePostError = (err) => {
    const ERROR_MESSAGE =
      err.response.status === 409
        ? 'Sorry, cannot add organisation because it already exists!'
        : 'Oops! There was a problem with that request.';

    setState((previousState) => ({
      ...previousState,
      msg: ERROR_MESSAGE,
      variant: 'danger',
      show: true
    }));
  };

  const handleSubmit = (input) => {
    const DATA = { organisation: input.organisation };

    axios.post('/admin/organisation', DATA).then(handlePostSuccess).catch(handlePostError);
  };

  const handleDelete = () => {
    state.selection
      ? deleteOrganisation()
      : setState((previousState) => ({
          ...previousState,
          msg: 'Please select an organisation to delete',
          variant: 'danger',
          show: true
        }));
  };

  const handleDeleteSuccess = () => {
    const newList = state.organisations.filter((item) => item.organisation !== state.selection);

    setState((previousState) => ({
      ...previousState,
      msg: 'Organisation removed successfully!',
      variant: 'success',
      show: true,
      organisations: newList,
      selection: ''
    }));
  };

  const handleDeleteError = (err) => {
    setState((previousState) => ({
      ...previousState,
      msg: 'Oops! There was a problem with that request',
      variant: 'danger',
      show: true
    }));
  };

  const deleteOrganisation = () => {
    axios
      .delete(`/admin/organisation/${state.selection}`)
      .then(handleDeleteSuccess)
      .catch(handleDeleteError);
  };

  const handleSelect = (e) => setState((previousState) => ({ ...previousState, selection: e }));

  useEffect(() => {
    axios.get('/admin/organisation').then(({ data }) => {
      setState((previousState) => ({ ...previousState, organisations: data, loading: false }));
    });
  }, []);

  return state.loading ? (
    <Loader />
  ) : (
    <OrganisationsView
      state={state}
      schema={SCHEMA}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      handleSelect={handleSelect}
      handleSearch={handleSearch}
    />
  );
};

export default Organisations;