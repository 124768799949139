import React from 'react';
import { Formik } from 'formik';
import {
  Jumbotron,
  Container,
  Button,
  Form,
  Alert,
  DropdownButton,
  Dropdown,
  InputGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserFriends as OrganisationsIcon,
  faTrash as Delete,
  faPlusCircle as Plus
} from '@fortawesome/free-solid-svg-icons';
import { SEO } from '../../Components';

const Organisations = ({
  schema,
  handleSubmit,
  state,
  handleSelect,
  handleSearch,
  handleDelete
}) => {
  const renderOrganisations = () => {
    let organisations = state.filtered.length > 0 ? state.filtered : state.organisations;
    organisations = organisations.sort((a, b) => a.organisation.localeCompare(b.organisation));
    return (
      organisations.length > 0 && (
        <InputGroup>
          {/*<Form.Control
            onChange={handleSearch}
            name="search"
            type="text"
            placeholder="Search..."
            maxLength="40"
          />*/}

          <DropdownButton
            id="organisation-list"
            className="mr-1"
            title={
              <>
                <FontAwesomeIcon icon={OrganisationsIcon} className="mr-1" />
                {state.selection || 'Organisations'}
              </>
            }
            onSelect={(e) => handleSelect(e)}
          >
            {organisations.map((organisation) => (
              <Dropdown.Item eventKey={organisation.organisation} key={organisation.id}>
                {organisation.organisation}
              </Dropdown.Item>
            ))}
          </DropdownButton>

          <Button variant="primary" onClick={handleDelete}>
            <FontAwesomeIcon icon={Delete} className="mr-1" /> Delete
          </Button>
        </InputGroup>
      )
    );
  };
  return (<>
    <SEO title='Organisations' description="View, add, remove and manage organisations." url='https://www.northwordproject.com/organisations' />
    <Container className="small row m-0 p-0" fluid>
      <Jumbotron className="small-mob2 my-auto h-sm-100 mx-lg-auto mx-0 w-lg-75 w-100">
        <h1>Organisations</h1>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{ organisation: state.organisation }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="organisations-form">
                <InputGroup className="test">
                  <Form.Control
                    onChange={handleChange}
                    name="organisation"
                    type="text"
                    placeholder="Enter an organisation..."
                    maxLength="40"
                    value={values.organisation}
                    isInvalid={touched.organisation && !!errors.organisation}
                  />

                  <Button className="mr-1" variant="primary" type="submit">
                    Add <FontAwesomeIcon icon={Plus} />
                  </Button>

                  <Form.Control.Feedback type="invalid">
                    {errors.organisation}
                  </Form.Control.Feedback>
                </InputGroup>
                <Form.Text muted>Enter an organisation.</Form.Text>

                {renderOrganisations()}
                <Form.Text muted>Select an organisation.</Form.Text>
                <Alert show={state.show} variant={state.variant}>
                  {state.msg}
                </Alert>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Jumbotron>
    </Container>
    </>
  );
};

export default Organisations;
