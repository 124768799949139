import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileSignature as Terms,
  faUserSecret as Privacy,
    faGlobe as Website
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook as Facebook,
  faInstagram as Instagram
} from '@fortawesome/free-brands-svg-icons';
import './style.scss';

const Footer = () => {
  const DATE = new Date();
  const YEAR = DATE.getFullYear();

  return (
    <Container fluid className="footer py-4" bg="contrast-primary">
      <Row className="m-1 text-center">
        <Col className="nav-link" md={2}>
          <a href="https://www.facebook.com/northwordstorytagging/">
            <FontAwesomeIcon icon={Facebook} className="mr-3" />
          </a>

          <a href="https://www.instagram.com/northwordstorytagging/">
            <FontAwesomeIcon icon={Instagram} className="mr-3" />
          </a>

          <a href="https://storytagging.interreg-npa.eu/">
            <FontAwesomeIcon icon={Website} />
          </a>
        </Col>

        <Col md={4}>
          <Nav.Link as={Link} to="#">
            <p>Copyright &copy; {YEAR} NorthWord</p>
          </Nav.Link>
        </Col>

        <Col md={3}>
          <Nav.Link as={Link} to="/privacy">
            <FontAwesomeIcon icon={Privacy} className="mr-1" />
            Privacy Policy
          </Nav.Link>
        </Col>

        <Col md={3}>
          <Nav.Link as={Link} to="/terms">
            <FontAwesomeIcon icon={Terms} className="mr-1" />
            Terms & Conditions
          </Nav.Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
