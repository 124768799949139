const countries = [
  {
    code: 'AX',
    name: 'Åland Islands'
  },
  {
    code: 'AL',
    name: 'Albania'
  },
  {
    code: 'AD',
    name: 'Andorra'
  },
  {
    code: 'AT',
    name: 'Austria'
  },
  {
    code: 'BY',
    name: 'Belarus'
  },
  {
    code: 'BE',
    name: 'Belgium'
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina'
  },
  {
    code: 'BG',
    name: 'Bulgaria'
  },
  {
    code: 'HR',
    name: 'Croatia'
  },
  {
    code: 'CY',
    name: 'Cyprus'
  },
  {
    code: 'CZ',
    name: 'Czech Republic'
  },
  {
    code: 'DK',
    name: 'Denmark'
  },
  {
    code: 'EE',
    name: 'Estonia'
  },
  {
    code: 'FO',
    name: 'Faroe Islands'
  },
  {
    code: 'FI',
    name: 'Finland'
  },
  {
    code: 'FR',
    name: 'France'
  },
  {
    code: 'DE',
    name: 'Germany'
  },
  {
    code: 'GR',
    name: 'Greece'
  },
  {
    code: 'GG',
    name: 'Guernsey'
  },
  {
    code: 'VA',
    name: 'Holy See (Vatican City State)'
  },
  {
    code: 'HU',
    name: 'Hungary'
  },
  {
    code: 'IC',
    name: 'Iceland'
  },
  {
    code: 'IE',
    name: 'Ireland'
  },
  {
    code: 'IM',
    name: 'Isle of Man'
  },
  {
    code: 'IT',
    name: 'Italy'
  },
  {
    code: 'JE',
    name: 'Jersey'
  },
  {
    code: 'LV',
    name: 'Latvia'
  },
  {
    code: 'LI',
    name: 'Liechtenstein'
  },
  {
    code: 'LT',
    name: 'Lithuania'
  },
  {
    code: 'LU',
    name: 'Luxembourg'
  },
  {
    code: 'MK',
    name: 'Macedonia, The Former Yugoslav Republic of'
  },
  {
    code: 'MT',
    name: 'Malta'
  },
  {
    code: 'MD',
    name: 'Moldova, Republic of'
  },
  {
    code: 'MC',
    name: 'Monaco'
  },
  {
    code: 'NL',
    name: 'Netherlands'
  },
  {
    code: 'NO',
    name: 'Norway'
  },
  {
    code: 'PL',
    name: 'Poland'
  },
  {
    code: 'PT',
    name: 'Portugal'
  },
  {
    code: 'RO',
    name: 'Romania'
  },
  {
    code: 'RU',
    name: 'Russian Federation'
  },
  {
    code: 'SM',
    name: 'San Marino'
  },
  {
    code: 'SK',
    name: 'Slovakia'
  },
  {
    code: 'SI',
    name: 'Slovenia'
  },
  {
    code: 'ES',
    name: 'Spain'
  },
  {
    code: 'SJ',
    name: 'Svalbard and Jan Mayen'
  },
  {
    code: 'SE',
    name: 'Sweden'
  },
  {
    code: 'CH',
    name: 'Switzerland'
  },
  {
    code: 'UA',
    name: 'Ukraine'
  },
  {
    code: 'GB',
    name: 'United Kingdom'
  }
];

export default countries;
