import React from 'react';
import '../style.scss';
import Deck from './Deck';
import {useLocation} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpellCheck as Review,
  faShare as Published,
  faBook as All
} from '@fortawesome/free-solid-svg-icons';
import { Confirm, Loader, SEO } from '../../../Components/';
const ManageStories = ({
  state,
  handleShowPublish,
  handleShowRetract,
  handleShowDelete,
  handlePublish,
  handleDelete,
  handleCancelPublish,
  handleCancelRetract,
  handleCancelDelete
}) => (
  <>
    {useLocation().pathname === '/stories/manage' ? <SEO title='Manage Stories' description='View, edit, publish, retract or delete stories.' url='https://www.northwordproject.com/stories/manage'/> : <SEO title='Other Stories' description='View stories submitted from other organisations.' url='https://www.northwordproject.com/stories/other' />}
    {state.loading ? (
      <Loader />
    ) : (
      <>
      
        <Deck
          stories={state.submitted}
          handleShowPublish={handleShowPublish}
          handleShowRetract={handleShowRetract}
          handleShowDelete={handleShowDelete}
          name={
            <>
              <FontAwesomeIcon icon={Review} className="mr-2" />
              Review For Publishing
            </>
          }
        />

        <Deck
          stories={state.released}
          handleShowPublish={handleShowPublish}
          handleShowRetract={handleShowRetract}
          handleShowDelete={handleShowDelete}
          name={
            <>
              <FontAwesomeIcon icon={Published} className="mr-2" />
              Published Stories
            </>
          }
        />

        <Deck
          stories={state.all.sort((a, b) => a.content[0].title.localeCompare(b.content[0].title))}
          handleShowPublish={handleShowPublish}
          handleShowRetract={handleShowRetract}
          handleShowDelete={handleShowDelete}
          name={
            <>
              <FontAwesomeIcon icon={All} className="mr-2" />
              All Stories
            </>
          }
        />

        <Confirm
          show={state.showPublish}
          handleCancel={handleCancelPublish}
          msg="Are you sure that the story is ready for publishing?"
          confirmText="Yes, Publish"
          confirmFunction={(e) => handlePublish(e, true)}
        />

        <Confirm
          show={state.showRetract}
          handleCancel={handleCancelRetract}
          msg="Are you sure that you want to retract the story? The public will not be able to view the story until it is published again."
          confirmText="Yes, Retract"
          confirmFunction={(e) => handlePublish(e, false)}
        />

        <Confirm
          show={state.showDelete}
          handleCancel={handleCancelDelete}
          msg="Once the story is deleted, it cannot be recovered."
          confirmText="Yes, Delete"
          confirmFunction={handleDelete}
        />
      </>
    )}
  </>
);

export default ManageStories;
