import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Rgu, Uhi, Kvarken, Kenozero, Vasterbotten, Ulster, Ccght } from '../../../Assets';

const Logos = () => (
  <Carousel controls={true} indicators={false}>
    <Carousel.Item>
      <a href='https://www.rgu.ac.uk/rgview/impactful-research/4102-celebrating-our-cultural-heritage-through-storytelling' className="carousel-container d-flex justify-content-center align-content-center">
          <img src={Rgu} alt="RGU" />
      </a>
    </Carousel.Item>
    <Carousel.Item>
      <a href='https://www.uhi.ac.uk/en/' className="carousel-container d-flex justify-content-center mx-auto">
        <img src={Uhi} alt="UHI" />
      </a>
    </Carousel.Item>
    <Carousel.Item>
      <a href='https://www.regionvasterbotten.se/' className="carousel-container d-flex justify-content-center mx-auto">
        <img src={Vasterbotten} alt="Vasterbotten" />
      </a>
    </Carousel.Item>
    <Carousel.Item>
      <a href='https://www.kvarken.org/en/project/storytagging/' className="carousel-container d-flex justify-content-center mx-auto">
        <img src={Kvarken} alt="Kvarken" />
      </a>
    </Carousel.Item>
    <Carousel.Item>
      <a href='https://www.ulster.ac.uk/research/topic/computer-science/intelligent-systems-research-centre/projects/storytagging' className="carousel-container d-flex justify-content-center mx-auto">
        <img src={Ulster} alt="Ulster University" />
      </a>
    </Carousel.Item>
    <Carousel.Item>
      <a href='https://ccght.org/northword-storytagging-opportunity-to-tender/' className="carousel-container d-flex justify-content-center mx-auto">
        <img src={Ccght} alt="CCGHT" />
      </a>
    </Carousel.Item>
  </Carousel>
);

export default Logos;
