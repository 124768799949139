import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { AddLanguage as Languages, Loader } from '../../Components';
import tracker from '../../HOC/Tracker';

tracker();

const AddLanguage = () => {
  const SCHEMA = yup.object().shape({
    language: yup
      .string()
      .required('*Languages is required')
      .min(3, '*Languages must be between 3-25 characters')
      .max(25, '*Languages must be between 3-25 characters')
  });

  const initialState = {
    language: '',
    selection: '',
    languages: [],
    filtered: [],
    search: '',
    loading: false
  };

  const [state, setState] = useState(initialState);

  const handleSearch = (e) => {
    const CATEGORIES = state.languages.filter((language) =>
      language.language.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setState((previousState) => ({ ...previousState, filtered: CATEGORIES }));
  };

  const handlePostSuccess = ({ data }) =>
    setState((previousState) => ({
      ...previousState,
      msg: 'Language added successfully!',
      variant: 'success',
      show: true,
      languages: [...previousState.languages, data],
      language: ''
    }));

  const handleResponse = (code) => {
    switch (code) {
      case 400:
        return 'Sorry, cannot delete the English language option.';

      case 404:
        return 'Sorry, we could not find the language to delete. Please try again!';

      case 409:
        return 'Cannot add this language, it already exists!';

      default:
        return 'Oops! The server encountered an unexpected problem with that request. Please try again.';
    }
  };

  const handlePostError = (err) => {
    const ERROR_MESSAGE = handleResponse(err.response.status);

    setState((previousState) => ({
      ...previousState,
      msg: ERROR_MESSAGE,
      variant: 'danger',
      show: true
    }));
  };

  const handleSubmit = (input) => {
    const DATA = { language: input.language };

    axios.post('/admin/language', DATA).then(handlePostSuccess).catch(handlePostError);
  };

  const handleDelete = () => {
    if (state.selection && state.selection !== 'English') {
      doDeletion();
    } else if (state.selection === 'English') {
      setState((previousState) => ({
        ...previousState,
        msg: 'Sorry, cannot delete the English language option.',
        variant: 'danger',
        show: true
      }));
    } else {
      setState((previousState) => ({
        ...previousState,
        msg: 'Please select a language to delete.',
        variant: 'danger',
        show: true
      }));
    }
  };

  const handleDeleteSuccess = () => {
    const newList = state.languages.filter((item) => item.language !== state.selection);

    setState((previousState) => ({
      ...previousState,
      msg: 'Language removed successfully!',
      variant: 'success',
      show: true,
      languages: newList,
      selection: ''
    }));
  };

  const handleDeleteError = (err) =>
    setState((previousState) => ({
      ...previousState,
      msg: handleResponse(err.response.status),
      variant: 'danger',
      show: true
    }));

  const doDeletion = () => {
    axios
      .delete(`/admin/language/${state.selection}`)
      .then(handleDeleteSuccess)
      .catch(handleDeleteError);
  };

  const handleSelect = (e) => setState((previousState) => ({ ...previousState, selection: e }));

  useEffect(() => {
    setState((previousState) => ({ ...previousState, loading: true }));
    axios.get('/admin/language').then(({ data }) => {
      setState((previousState) => ({ ...previousState, languages: data, loading: false }));
    });
  }, []);

  return state.loading ? (
    <Loader />
  ) : (
    <Languages
      state={state}
      schema={SCHEMA}
      handleSearch={handleSearch}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      handleSelect={handleSelect}
    />
  );
};

export default AddLanguage;
