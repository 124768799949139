import React from 'react';
import { Formik } from 'formik';
import {
  Jumbotron,
  Container,
  Button,
  Form,
  Alert,
  DropdownButton,
  Dropdown,
  InputGroup
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLanguage as Language,
  faTrash as Delete,
  faPlusCircle as Plus
} from '@fortawesome/free-solid-svg-icons';
import { SEO } from '../../Components';

const AddLanguage = ({ state, schema, handleSubmit, handleDelete, handleSelect }) => {
  const renderLanguages = () => {
    let languages = state.filtered.length > 0 ? state.filtered : state.languages;
    languages = languages.sort((a, b) => a.language.localeCompare(b.language));
    return (
      languages.length > 0 && (
        <InputGroup>
          <DropdownButton
            id="language-list"
            title={
              <>
                <FontAwesomeIcon icon={Language} className="mr-1" />
                {state.selection || 'Languages'}
              </>
            }
            onSelect={(e) => handleSelect(e)}
            className="mr-1"
          >
            {languages.map((language) => (
              <Dropdown.Item
                disabled={language.language === 'English'}
                eventKey={language.language}
                key={language.id}
              >
                {language.language}
                {language.language === 'English' && ' (Required)'}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <Button variant="primary" onClick={handleDelete}>
            <FontAwesomeIcon icon={Delete} className="mr-1" />
            Delete
          </Button>
        </InputGroup>
      )
    );
  };

  return (
    <>
    <SEO title='Languages' description="View, add, remove and manage languages." url='https://www.northwordproject.com/languages'/>
    <Container className="small row m-0 p-0" fluid>
      <Jumbotron className="small-mob2 my-auto h-sm-100 mx-lg-auto mx-0 w-lg-75 w-100">
        <h1>Languages</h1>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{ language: state.language }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="languages-form">
                <InputGroup>
                  <Form.Control
                    onChange={handleChange}
                    name="language"
                    type="text"
                    placeholder="Enter a language..."
                    maxLength="25"
                    value={values.language}
                    isInvalid={touched.language && !!errors.language}
                  />

                  <Button className="mr-1" variant="primary" type="submit">
                    Add <FontAwesomeIcon icon={Plus} />
                  </Button>
                  <Form.Control.Feedback type="invalid">{errors.language}</Form.Control.Feedback>
                </InputGroup>

                <Form.Text muted>Enter a language.</Form.Text>

                {renderLanguages()}

                <Alert show={state.show} variant={state.variant}>
                  {state.msg}
                </Alert>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Jumbotron>
      </Container>
      </>
  );
};

export default AddLanguage;
