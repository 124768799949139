import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import View from '../../../Containers/Story/View/view';
import './style.scss';
const Preview = ({ show, handleCancel, handleSelect, title, size, index, state }) => (
  <Modal show={show} onHide={handleCancel} keyboard={false} size={size}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>

    <Modal.Body className="preview-modal">
      <View
        loading={false}
        content={state.story.content}
        index={index}
        location={state.story.location_name}
        header={state.story.header}
        preview={true}
      />
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={handleCancel}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

export default Preview;
