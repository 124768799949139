import React, { useState, useEffect, useMemo } from 'react';
import RegisterView from './view';
import * as yup from 'yup';
import axios from 'axios';
import { Eye, EyeSlash } from '../../../Assets';
import tracker from '../../../HOC/Tracker';

tracker();

const Register = ({ token }) => {
  const OPTIONS = useMemo(
    () => ({
      headers: {
        Authorization: token
      }
    }),
    [token]
  );

  useEffect(() => {
    axios.get('/admin/organisation', OPTIONS).then(({ data }) =>
      setState((previousState) => ({
        ...previousState,
        organisations: data.map((org) => org.organisation).sort((a, b) => a.localeCompare(b)),
        loading: false
      }))
    );
  }, [OPTIONS]);

  const INITIAL_STATE = {
    username: '',
    password: '',
    role: 'COLLABORATOR',
    organisation: '',
    show: false,
    variant: 'danger',
    msg: '',
    organisations: [],
    eye: Eye,
    type: 'password',
    confirmEye: Eye,
    confirmType: 'password',
    loading: false
  };

  const [state, setState] = useState(INITIAL_STATE);

  const handleSelect = (e, setFieldValue) => {
    setFieldValue('organisation', e);
    setState((previousState) => ({
      ...previousState,
      organisation: e
    }));
  };

  const handleCheck = () =>
    setState((previousState) => ({
      ...previousState,
      role: state.role === 'ADMIN' ? 'COLLABORATOR' : 'ADMIN'
    }));

  const handleSuccess = () =>
    setState((previousState) => ({
      ...previousState,
      msg: 'User created successfully!',
      variant: 'success',
      show: true
    }));

  const handleError = ({ response }) =>
    setState((previousState) => ({
      ...previousState,
      msg: response.data,
      variant: 'danger',
      show: true
    }));

  const handleSubmit = (input) => {
    const DATA = {
      username: input.username,
      password: input.password,
      role: state.role === 'ADMIN' ? 'ADMIN' : 'COLLABORATOR',
      organisation: input.organisation
    };

    axios.post(`/user`, DATA, OPTIONS).then(handleSuccess).catch(handleError);
  };

  const SCHEMA = yup.object().shape({
    username: yup
      .string()
      .required('*Username is required')
      .min(3, '*Username must be between 3-25 characters')
      .max(25, '*Username must be between 3-25 characters')
      .matches(
        /^[a-zA-Z0-9_-]*$/,
        '*Username must only contain letters, numbers, hyphens or underscores.'
      ),
    organisation: yup
      .string()
      .required('*Organisation is required')
      .oneOf(state.organisations, '*Organisation invalid, please pick one from the list.'),
    password: yup
      .string()
      .required('*Password is required')
      .min(8, '*Password must be between 3-15 characters')
      .max(15, '*Password must be between 3-15 characters')
      .matches(/\d/, '*Password must contain at least one number')
      .matches(/[a-z]/, '*Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, '*Password must contain at least one uppercase letter'),
    check: yup
      .string()
      .required('*Checking password is required')
      .oneOf([yup.ref('password'), null], "*Passwords don't match"),
    role: yup.boolean()
  });

  const toggleEye = () =>
    setState((previousState) => ({
      ...previousState,
      type: state.type === 'password' ? 'text' : 'password',
      eye: state.eye === Eye ? EyeSlash : Eye
    }));

  const toggleConfirmEye = () =>
    setState((previousState) => ({
      ...previousState,
      confirmType: state.confirmType === 'password' ? 'text' : 'password',
      confirmEye: state.confirmEye === Eye ? EyeSlash : Eye
    }));

  return (
    <RegisterView
      schema={SCHEMA}
      state={state}
      handleSubmit={handleSubmit}
      handleSelect={handleSelect}
      handleCheck={handleCheck}
      toggleEye={toggleEye}
      toggleConfirmEye={toggleConfirmEye}
    />
  );
};

export default Register;
