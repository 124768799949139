import React, { useState, useEffect } from 'react';
import axios from 'axios';
import View from './view';
import tracker from '../../../HOC/Tracker';

tracker();

const ViewStory = ({ match }) => {
  const [state, setState] = useState({
    header: '',
    content: [
      {
        title: '',
        subtitle: '',
        language: '',
        summary: '',
        story_text: '',
        audio: ''
      }
    ],
    location: '',
    index: 0,
    loading: true,
    product: {}
  });

  const handleSuccess = axios.spread((...res) => {
    const PRODUCT = res[1].data.find((e) => e.story_id === match.params.id);

    setState((previousState) => ({
      ...previousState,
      content: res[0].data[0].content,
      id: res[0].data[0].id,
      header: res[0].data[0].header,
      location: res[0].data[0].location_name,
      loading: false,
      product: PRODUCT && {
        id: PRODUCT.id,
        content: PRODUCT.content,
        released: PRODUCT.released
      }
    }));
  });

  useEffect(() => {
    const HEADERS = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const getStories = axios.get(`/story/${match.params.id}`, HEADERS);
    const getProducts = axios.get(`/product`);

    axios.all([getStories, getProducts]).then(handleSuccess);
  }, []);

  return (
    <View
      id={state.id}
      loading={state.loading}
      content={state.content}
      index={state.index}
      location={state.location}
      header={state.header}
      handleSelect={(value) => setState((previousState) => ({ ...previousState, index: value }))}
      product={state.product}
    />
  );
};

export default ViewStory;
