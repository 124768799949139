import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  AddLanguage,
  Login,
  Categories,
  TellingStories,
  SkillsForStories,
  UsingStories,
  Organisations
} from './Containers';
import { Cookies as CookiesComponent, NavBar, Footer, Loader, Home, About, NotFound, Policy, Terms } from './Components';
import { ProtectedRoute, AdminRoute, Tracker } from './HOC';
import Auth from './HOC/Auth';
import Register from './Containers/User/Register';
import ManageUsers from './Containers/User/ManageUsers';
import EditUser from './Containers/User/Edit';
import ChangePassword from './Containers/User/ChangePassword';

import ViewStory from './Containers/Story/View';
import Editor from './Containers/Story/Editor';
import ManageStories from './Containers/Story/Manage';
import OurStories from './Containers/Story/Our';

import Cookies from 'universal-cookie';
import './App.scss';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


//const bc = new BroadcastChannel('loginChannel');
const App = () => {
  const cookies = new Cookies();
  
  const [state, setState] = useState({
    user: '',
    org: '',
    admin: false,
    token: null,
    loggedIn: false,
    loading: true,
    accepted:  false
  });

  
  const accept = () =>
  {
    const ONE_MONTH = 31 * 24 * 60 * 60 * 1000;
  
  cookies.set('allowCookies', 'true', { path: '/', maxAge: ONE_MONTH});
    setState((previousState) => ({
      ...previousState,
      accepted: true,
    }))};

  const clearStorage = () => {
    localStorage.removeItem('user', state.user);
    localStorage.removeItem('org', state.org);
    localStorage.removeItem('admin', state.admin);
    localStorage.removeItem('loggedIn', state.loggedIn);
  }
  const handleLogout = () => {
    axios.delete('/login').then(() => {
      setState((previousState) => ({
        ...previousState,
        user: '',
        org: '',
        admin: false,
        token: null,
        loggedIn: false
      }));

      clearStorage();

      //bc.postMessage('logged out');
    });
  };

  //bc.onmessage = function () { handleLogout() }
  

  const login = (token, user, org, admin) => {
    setState(previousState => ({...previousState, token: token, user:user, org:org, admin:admin, loggedIn: true}))
    localStorage.setItem('user', user);
    localStorage.setItem('org', org);
    localStorage.setItem('admin', admin);
    localStorage.setItem('loggedIn', true);
  }


  useEffect(() => {
    setState(previousState => ({
      ...previousState, loggedIn: !!localStorage.getItem('loggedIn'),
      admin: !!localStorage.getItem('admin'),
      org: localStorage.getItem('org') ?? '',
      user: localStorage.getItem('user') ?? '',
      accepted: !!cookies.get('allowCookies'),
      loading: false
    }))

  }, [])
  
 

  useEffect(() => {
    const onReceiveMessage = (e) => {
      const { key, newValue } = e;
      if (key === "loggedIn") {
        if (!newValue) {
          setState(previousState => ({...previousState, loggedIn: newValue, user: '', org: '', admin: false}));
        }
        
      }
    };
    window.addEventListener("storage", onReceiveMessage);
    return () => {
      window.removeEventListener("storage", onReceiveMessage);
    };
  }, []);



  return state.loading ? (
    <Loader />
  ) : (
    <>
      <Router>
        <Auth state={state} setState={setState}>
          <div className="nav-contain">
              <NavBar handleLogout={handleLogout} loggedIn={state.loggedIn} admin={state.admin} username={state.user}/>
          </div>
          <div className="main">
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/about" exact component={About} />
              <AdminRoute
                path="/stories/manage"
                exact
                admin={state.admin}
                token={state.token}
                loggedIn={state.loggedIn}
                component={ManageStories}
              />

              <ProtectedRoute
                path="/stories/our"
                exact
                loggedIn={state.loggedIn}
                token={state.token}
                user={state.user}
                organisation={state.org}
                component={OurStories}
              />
              <ProtectedRoute
                path="/stories/other"
                exact
                loggedIn={state.loggedIn}
                token={state.token}
                user={state.user}
                organisation={state.org}
                component={ManageStories}
              />
       
             
              <Route path="/stories/telling" exact component={TellingStories} />
                <Route path="/stories/skills" exact component={SkillsForStories} />
                <Route path="/stories/using" exact component={UsingStories} />

              <Route path="/stories/:id" component={ViewStory} />
              <Route path="/privacy" component={Policy} />
              <Route path="/terms" component={Terms} />
                
     

              

              <AdminRoute
                path="/categories"
                exact
                component={Categories}
                token={state.token}
                admin={state.admin}
                loggedIn={state.loggedIn}
              />
              <AdminRoute
                path="/organisations"
                exact
                component={Organisations}
                token={state.token}
                admin={state.admin}
                loggedIn={state.loggedIn}
              />
              <AdminRoute
                path="/languages"
                exact
                component={AddLanguage}
                token={state.token}
                admin={state.admin}
                loggedIn={state.loggedIn}
              />

              <ProtectedRoute
                path={['/editor', '/editor/:id']}
                exact
                component={Editor}
                loggedIn={state.loggedIn}
                token={state.token}
                user={state.user}
                org={state.org}
              />

              <AdminRoute
                path="/users"
                exact
                component={ManageUsers}
                token={state.token}
                admin={state.admin}
                loggedIn={state.loggedIn}
              />
              <AdminRoute
                path="/users/edit/:original"
                component={EditUser}
                token={state.token}
                admin={state.admin}
                loggedIn={state.loggedIn}
              />
              <ProtectedRoute
                path="/users/changepassword/:username"
                component={ChangePassword}
                token={state.token}
                admin={state.admin}
                loggedIn={state.loggedIn}
              />
              <Route path="/login" render={() => <Login login={login} />} />
              <AdminRoute
                path="/register"
                component={Register}
                token={state.token}
                admin={state.admin}
                loggedIn={state.loggedIn}
              />
              <Route component={NotFound} />
            </Switch>
          </div>

          <Footer />
          {state.loggedIn && !state.accepted && <CookiesComponent accept={accept} />}
        </Auth>
      </Router>
    </>
  );
};
export default App;
