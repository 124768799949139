import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import EditorView from './presentation';
import { Tab } from 'react-bootstrap';
import { Translations, Loader } from '../../../Components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage as Language } from '@fortawesome/free-solid-svg-icons';
import SCHEMA from '../../../Validation/story.schema';
import tracker from '../../../HOC/Tracker';

tracker();

const Editor = ({ org, user, token, computedMatch }) => {
  const [show, setShow] = useState({ show: false, language: '' });
  const [save, setSave] = useState({
    show: false,
    key: '',
    saved: false,
    id: '',
    error: ''
  });

  const INITIAL_STORY = useMemo(
    () => ({
      content: [
        {
          title: '',
          subtitle: '',
          story_text: '',
          summary: '',
          language: 'English',
          audio: ''
        }
      ],
      organisation_submitting: org,
      longitude: 0,
      latitude: 0,
      location_name: '',
      country: '',
      keywords: [],
      categories: [],
      username: user,
      author: '',
      header: '',
      submitted: false,
      released: false
    }),
    [org, user]
  );

  const [state, setState] = useState({
    story: INITIAL_STORY,
    key: 'English',
    languages: [],
    tab: [],
    show: false,
    loading: false,
    errors: false,
    type: 'Stories'
  });

  useEffect(() => {
    if (state.type === 'Making') {
      setState(prev => ({...prev, location_name:'000'}))
    }
  }, [state.type])

  const HEADERS = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  const restoreStory = ({ data }) => {
    let languages = [];
    let tabs = [];

    const testState = {
        ...state,
        story: {
          content: data[0].content ?? [],
          organisation_submitting: data[0].organisation_submitting ?? '',
          longitude: data[0].location?.coordinates[1] ?? 0,
          latitude: data[0].location?.coordinates[0] ?? 0,
          location_name: data[0].location_name ?? '',
          country: data[0].country ?? '',
          keywords: data[0].keywords ?? [],
          categories: data[0].categories ?? [],
          username: data[0].username ?? '',
          header: data[0].header ?? '',
          author: data[0].author ?? '',
          submitted: false,
          released: false,
        }
      };

    data[0].content.forEach((story, i) => {
      
      if (story.language !== 'English') {
        languages.push(story.language);
        tabs.push(
          <Tab
            eventKey={story.language}
            title={
              <>
                <FontAwesomeIcon icon={Language} className="mr-1" />
                <span>
                  {story.language}
                  <button
                    onClick={() => setShow({ show: true, language: story.language })}
                    type="button"
                    className="close ml-1"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </span>
              </>
            }
          >
            <Translations
              index={i}
              language={story.language}
              setState={setState}
              state={testState}
              token={token}
              user={user}
              org={org}
            />
          </Tab>
        );

        const temp = tabs;
        tabs.forEach((tab) => {
          tab.props.children.props.state.tab = temp;
        });
      }
    });

    setState({
      story: {
        content: data[0].content ?? [],
        organisation_submitting: data[0].organisation_submitting || '',
        longitude: data[0].location?.coordinates[1] ?? 0,
        latitude: data[0].location?.coordinates[0] ?? 0,
        location_name: data[0].location_name ?? '',
        country: data[0].country ?? '',
        keywords: data[0].keywords ?? [],
        categories: data[0].categories ?? [],
        username: data[0].username ?? '',
        header: data[0].header ?? '',
        author: data[0].author ?? '',
        submitted: false,
        released: false
      },
      tab: tabs,
      languages: languages,
      key: 'English',
      show: false,
      loading: false,
      errors: false
    });

    setSave({ ...save, saved: true, id: computedMatch.params.id });
  };

  const validate = () =>
    SCHEMA()
      .then((res) => res.validate(state.story, { abortEarly: false }))
      .then(() => setSave({ ...save, show: true, key: state.key }))
      .catch(({ errors }) =>
        setSave({
          ...save,
          errors: true,
          show: true,
          key: state.key
        })
      );

  useEffect(() => {
    if (computedMatch.params.id) {
      setState((previousState) => ({ ...previousState, loading: true }));
      axios.get(`/story/${computedMatch.params.id}`, HEADERS).then(restoreStory);
    }
  }, []);

  useEffect(() => {
    window.onbeforeunload = () => {
      if (JSON.stringify(state.story) !== JSON.stringify(INITIAL_STORY))
        return 'Data will be lost if you leave the page, are you sure?';
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [state, INITIAL_STORY]);

  const initialiseTranslation = (language) => {
    const content = state.story.content;
    content.push({
      language: language,
      title: '',
      subtitle: '',
      summary: '',
      story_text: '',
      audio: ''
    });

    return content;
  };

  const buildTab = (language) => {
    const tabs = state.tab;
    tabs.push(
      <Tab
        eventKey={language}
        title={
          <>
            <FontAwesomeIcon icon={Language} className="mr-1" />
            <span>
              {language}
              <button
                onClick={() => setShow({ show: true, language: language })}
                type="button"
                className="close ml-1"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </span>
          </>
        }
      >
        <Translations
          index={state.story.content.length - 1}
          language={language}
          setState={setState}
          state={state}
          token={token}
          user={user}
          org={org}
        />
      </Tab>
    );

    return tabs;
  };
  const createTab = (language) => {
    const content = initialiseTranslation(language);

    const tabs = buildTab(language);

    const languages = state.languages;
    languages.push(language);

    setState((previousState) => ({
      ...previousState,
      key: language,
      story: { ...previousState.story, content: content },
      languages: languages,
      tab: tabs
    }));
  };

  const handleRemove = (language) => (Array.isArray(language) ? removeAll() : removeOne(language));

  const removeAll = () => {
    const content = [state.story.content[0]];

    setState((previousState) => ({
      ...previousState,
      story: { ...previousState.story, content: content },
      tab: [],
      key: 'English',
      languages: []
    }));
    setShow({ show: false, language: '' });
  };
  const removeOne = (language) => {
    let content = state.story.content;
    content = content.filter((translation) => translation.language !== language);

    let tabs = state.tab;
    tabs = tabs.filter((tab) => tab.props.eventKey !== language);

    let languages = state.languages;
    languages = languages.filter((lang) => lang.value !== language);

    setState((previousState) => ({
      ...previousState,
      story: { ...previousState.story, content: content },
      tab: tabs,
      key: 'English',
      languages: languages
    }));
    setShow({ show: false, language: '' });
  };

  const handleSuccess = ({ data }) => {
    setState((previousState) => ({
      ...previousState,
      key: save.key
    }));
    setSave({
      show: false,
      saved: true,
      key: '',
      id: data.id || save.id,
      error: ''
    });
  };
  const handleError = ({ response: { status } }) =>
    status === 403
      ? setSave({
          ...save,
          error: 'Oops! It appears you have been logged out. Please login and try again.'
        })
      : setSave({
          ...save,
          error:
            'Oops! An unexpected server error occurred. Please check that you are online and try again.'
        });

  const handleSave = () => {
    const headers = {
      headers: { Authorization: token }
    };

    save.saved === true
      ? axios.put(`/story/${save.id}`, state.story, headers).then(handleSuccess).catch(handleError)
      : axios.post(`/story`, state.story, headers).then(handleSuccess).catch(handleError);
  };

  const handleSelect = (key) => {
    key === 'save'
      ? validate()
      : setState((previousState) => ({
          ...previousState,
          key: key
        }));
  };

  return state.loading ? (
    <Loader />
  ) : (
    <EditorView
      state={state}
      setState={setState}
      createTab={createTab}
      handleRemove={handleRemove}
      handleCancel={() => setShow({ ...show, show: false })}
      token={token}
      org={org}
      user={user}
      show={show}
      setShow={setShow}
      handleCancelSave={() => setSave({ ...save, show: false, key: '' })}
      handleSave={handleSave}
      handleSelect={handleSelect}
      save={save}
    />
  );
};

export default Editor;
