import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import DeckStories from './view';
import SCHEMA from '../../../Validation/story.schema';
import tracker from '../../../HOC/Tracker';

tracker();

const OurStories = ({ token, organisation }) => {
  const INITIAL_STATE = {
    draft: [],
    submitted: [],
    our: [],
    published: [],
    showSubmit: false,
    showWithdraw: false,
    showDelete: false,
    showFix: false,
    id: null,
    loading: false,
    errors: []
  };

  const [state, setState] = useState(INITIAL_STATE);

  const history = useHistory();

  const OPTIONS = {
    headers: { Authorization: token }
  };

  const sortStories = (stories) => ({
    draft: stories.filter((story) => story.submitted === false).reverse(),
    our: stories,
    submitted: stories.filter((story) => story.submitted && !story.released),
    published: stories.filter((story) => story.submitted && story.released)
  });

  const handleCancelSubmit = () =>
    setState((previousState) => ({ ...previousState, showSubmit: false }));
  const handleCancelWithdraw = () =>
    setState((previousState) => ({ ...previousState, showWithdraw: false }));
  const handleCancelDelete = () =>
    setState((previousState) => ({ ...previousState, showDelete: false }));
  const handleCancelFix = () => setState((previousState) => ({ ...previousState, showFix: false }));

  const handleSubmit = (e, submitted) => {
    const DATA = {
      submitted: submitted,
      released: false
    };

    const STORIES = toggleSubmit(state.our, submitted);
    const SORTED_STORIES = sortStories(STORIES);

    axios.put('/story/' + state.id, DATA, OPTIONS).then(() =>
      setState((previousState) => ({
        ...previousState,
        ...SORTED_STORIES,
        showSubmit: false,
        showWithdraw: false
      }))
    );
  };

  const getStories = () => {
    axios
      .get(`/story?organisation=${organisation}`)
      .then(({ data }) =>
        setState((previousState) => ({ ...previousState, ...sortStories(data), loading: false }))
      );
  };

  useEffect(getStories, [organisation]);

  const toggleSubmit = (stories, submitted) => {
    const STORY_INDEX = stories.findIndex((story) => story.id === state.id);

    if (STORY_INDEX >= 0) {
      stories[STORY_INDEX].submitted = submitted;
      stories[STORY_INDEX].released = false;
    }

    return stories;
  };

  const handleDelete = () =>
    axios.delete('/story/' + state.id, OPTIONS).then(() => {
      const STORIES = state.our.filter((story) => story.id !== state.id);
      setState((previousState) => ({
        ...previousState,
        ...sortStories(STORIES),
        showDelete: false
      }));
    });

  const validate = (e, id) =>
    axios.get(`/story/${id}`).then(({ data }) => {
      SCHEMA()
        .then((res) => res.validate(data[0], { abortEarly: false }))
        .then(() => handleShowSubmit(id))
        .catch(({ errors }) =>
          setState((previousState) => ({ ...previousState, showFix: true, errors: errors, id: id }))
        );
    });

  const handleShowSubmit = (id) =>
    setState((previousState) => ({ ...previousState, id: id, showSubmit: true }));

  const handleShowWithdraw = (e, id) =>
    setState((previousState) => ({ ...previousState, id: id, showWithdraw: true }));

  const handleShowDelete = (e, id) =>
    setState((previousState) => ({ ...previousState, id: id, showDelete: true }));

  const handleFix = () => {
    history.push(`/editor/${state.id}`);
  };

  return (
    <DeckStories
      state={state}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      handleShowSubmit={validate}
      handleShowWithdraw={handleShowWithdraw}
      handleShowDelete={handleShowDelete}
      handleFix={handleFix}
      handleCancelSubmit={handleCancelSubmit}
      handleCancelWithdraw={handleCancelWithdraw}
      handleCancelDelete={handleCancelDelete}
      handleCancelFix={handleCancelFix}
    />
  );
};

export default OurStories;
