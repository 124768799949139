import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { Hero, Languages, Translations, Map, Confirm, SEO } from '../../../Components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt as Marker,
  faCamera as Banner,
  faLanguage as Language,
  faBook as Book,
  faSave as SaveIcon
} from '@fortawesome/free-solid-svg-icons';

const Editor = ({
  state,
  setState,
  token,
  createTab,
  handleCancel,
  handleRemove,
  handleCancelSave,
  handleSave,
  handleSelect,
  org,
  user,
  show,
  setShow,
  save
}) => (
  <>
    <SEO title='Story Editor' description="Curate a story for the NorthWord platform." url='https://www.northwordproject.com/editor'/>
    <Tabs activeKey={state.key} onSelect={(key) => handleSelect(key)}>
      <Tab
        eventKey="location"
        title={
          <>
            <FontAwesomeIcon icon={Marker} className="mr-1" />
            Location
          </>
        }
      >
        <Map state={state} setState={setState} type="story" />
      </Tab>

      <Tab
        eventKey="hero"
        title={
          <>
            <FontAwesomeIcon icon={Banner} className="mr-1" />
            Banner
          </>
        }
      >
        <Hero state={state} token={token} setState={setState} org={org} user={user} type="story" />
      </Tab>

      <Tab
        eventKey="English"
        title={
          <>
            <FontAwesomeIcon icon={Book} className="mr-1" />
            English
          </>
        }
      >
        <Translations
          setState={setState}
          index={0}
          language="English"
          state={state}
          token={token}
          org={org}
          user={user}
          type="story"
        />
      </Tab>

      {[state.tab]}

      <Tab
        eventKey="languages"
        title={
          <>
            <FontAwesomeIcon icon={Language} className="mr-1" />
            Languages
          </>
        }
      >
        <Languages
          createTab={createTab}
          state={state}
          setState={setState}
          setShow={setShow}
          type="story"
        />
      </Tab>

      <Tab eventKey={'save'} title={<>
            <FontAwesomeIcon icon={SaveIcon} className="mr-1" />
            Save
          </>}></Tab>
    </Tabs>

    <Confirm
      show={show.show}
      handleCancel={handleCancel}
      msg="Are you sure that you want to remove the translation(s)? All information relating to the translation(s) will be lost."
      confirmText="Yes, Remove"
      confirmFunction={() => handleRemove(show.language)}
    />

    <Confirm
      show={save.show}
      handleCancel={handleCancelSave}
      msg={
        save.errors
          ? 'There are some errors with the story and once saved everyone in your organisation may view the story.'
          : 'Once saved everyone in your organisation may view the story.'
      }
      confirmText="Yes, Save"
      confirmFunction={handleSave}
      error={save.error}
    />
  </>
);

export default Editor;
