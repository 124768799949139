import React, {useState} from 'react';
import GoogleMapReact from 'google-map-react';
import { Container, Row, Popover, OverlayTrigger, Button, PopoverTitle } from 'react-bootstrap';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen as Book, faMapPin as Pin } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default ({ stories, location }) => {
  const DEFAULT_LAT = 59.540331856806795,
    DEFAULT_LONG = 16.96201370971681;

  const [state, setState] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  });

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "67vh",
  };

  const onMarkerClick = (props, marker, e) =>
    setState(previousState => ({
      ...previousState,
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    }));

  return (
    <Container className="m-0 p-0" fluid>
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: 'AIzaSyClnSujL8ebJ_tAadgbEU9p0zRJ8Ke6FVI' }}
          defaultCenter={{
            lat: DEFAULT_LAT,
            lng: DEFAULT_LONG
          }}
          center={{ lat: location.lat, lng: location.long }}
          defaultZoom={5}
        >
          <OverlayTrigger
            lat={location.lat}
            lng={location.long}
            trigger="click"
            placement="right"
            overlay={<PopoverTitle>You are here</PopoverTitle>}
            rootClose={true}
          >
            <FontAwesomeIcon
              icon={Pin}
              className="anchor"
              type="location"
              style={{ cursor: 'pointer', color: '#a6634b' }}
              id={1}
              text="You are here"
              size="3x"
            />
          </OverlayTrigger>

          {stories.filter(story => story.location_name !== '000').map((story) => {
            console.log(story.content[0].title)
            const popover2 = (
              <Popover id="popover-basic">
                <Popover.Title className="d-flex justify-content-center">
                  {story.content[0].title}
                </Popover.Title>
                <Popover.Content>
                  <p className="text-center">{story.content[0].summary}</p>
                  <Row className="d-flex justify-content-center">
                    <Button variant="primary" as={Link} to={'/stories/' + story.id}>
                      <FontAwesomeIcon icon={Book} className="mr-1" />
                      Read More...
                    </Button>
                  </Row>
                </Popover.Content>
              </Popover>
            );
            return (
              <OverlayTrigger
                lat={story?.location?.coordinates?.[0]}
                lng={story?.location?.coordinates?.[1]}
                trigger="click"
                placement="right"
                overlay={popover2}
                rootClose={true}
                key={story.id}
              >
                <FontAwesomeIcon
                  icon={Book}
                  style={{ cursor: 'pointer', color: '#534659' }}
                  title={story.content[0].title}
                  type="story"
                  summary={story.content[0].summary}
                  id={story.id}
                  key={story.id}
                  size="2x"
                  className="anchor"
                />
              </OverlayTrigger>
            );
          })}
        </GoogleMapReact>
      </div>
    </Container>
  );
};
