import React  from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button, Container, Alert, OverlayTrigger, Popover } from 'react-bootstrap';
import { ImageUnavailable as Placeholder } from '../../../../Assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen as Book, faMapMarkerAlt as Pin, } from '@fortawesome/free-solid-svg-icons';
import ScrollContainer from 'react-indiana-drag-scroll'

const Deck = ({ stories, name, byline, type }) => {
  const handleError = (e) => {
    e.target.onerror = null;
    e.target.src = Placeholder;
  };
  const list = stories.map((story) => {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">{story.content[0].title}</Popover.Title>

        <h5 className="popover-subtitle">{story.content[0].subtitle}</h5>

        <Popover.Content>{story.content[0].summary}</Popover.Content>
      </Popover>
    );

    return (
      <Col key={story.id} className="container-fluid mt-4">
        <OverlayTrigger trigger={['hover', 'focus']} placement="auto-end" overlay={popover}>
          <Card className="story-card">
            <Card.Img variant="top" src={story.header || Placeholder} onError={handleError} />

            <Card.Body>
              <Card.Title>{story.content[0].title || 'Untitled'}</Card.Title>
           { type === 'Telling Stories' &&  <span><FontAwesomeIcon icon={Pin} className="mr-1" /> {story.location_name}</span>}
            </Card.Body>

            <Card.Footer>
              <div className="d-flex justify-content-center">
                <Button variant="primary" as={Link} to={'/stories/' + story.id} key={story.id}>
                  <FontAwesomeIcon icon={Book} className="mr-1" />
                  Read More...
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </OverlayTrigger>
      </Col>
    );
  });

  return (
    <Container fluid className="mt-5 deck-container explore-container">
      <Row className="justify-content-center">
        <Button disabled className="py-3 px-5 deck-header" variant="secondary">
          <h1>{name}</h1>
          <h2>{byline}</h2>
        </Button>
      </Row>
      {list.length ? (
        <Row>
          <ScrollContainer>
            <div className="deck d-flex pb-4">
              {list}
            </div>
          </ScrollContainer>
        </Row>
      ) : (
        <Row className="no-stories my-auto">
          <Alert variant="info" className="my-auto mx-auto w-75">
            No stories yet.
          </Alert>
        </Row>
      )}
    </Container>
  );
};

export default Deck;
