import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

const Error = ({ show, msg, errors, handleCancel, confirmText, confirmFunction }) => (
  <Modal show={show} onHide={handleCancel} backdrop="static" keyboard={false} className="error">
    <Modal.Header closeButton>
      <Modal.Title>Uh oh!</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {msg}
      <br />
      <ul>
        {errors?.map((err) => (
          <li key={uuid()}>{err}</li>
        ))}
      </ul>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>
      <Button variant="primary" onClick={confirmFunction}>
        {confirmText}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default Error;
