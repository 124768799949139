import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Button, Container, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit as Edit,
  faTrash as Delete,
  faKey as Password
} from '@fortawesome/free-solid-svg-icons';
import ScrollContainer from 'react-indiana-drag-scroll';

const Deck = ({ users, organisation, handleShow, handleCancel }) => {
  const list = users.map((user) => (
    <Col className="container-fluid mt-4">
      <Card key={user.id} className="user-card">
        <Card.Body>
          <Card.Title>{user.username}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {user.role === 'ADMIN' ? 'Admin' : 'Collaborator'}
          </Card.Subtitle>
        </Card.Body>
        <Card.Footer>
          <div className="d-flex justify-content-center">
            <Button variant="primary" as={Link} to={'/users/edit/' + user.username}>
              <FontAwesomeIcon icon={Edit} className="mr-1" />
              Edit
            </Button>
            <Button variant="primary" onClick={(e) => handleShow(e, user.username)}>
              <FontAwesomeIcon icon={Delete} className="mr-1" />
              Delete
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </Col>
  ));

  return (
    <Container fluid className="mt-5 deck-container">
      <Row className="justify-content-center">
        <Button disabled className="py-3 px-5 deck-header" variant="secondary">
          <h1>{organisation}</h1>
        </Button>
      </Row>
      {list.length ? (
       <Row>
          <ScrollContainer>
            <div className="deck d-flex pb-4">
              {list}
            </div>
          </ScrollContainer>
        </Row>
      ) : (
        <Row className="no-stories my-auto">
          <Alert variant="info" className="my-auto mx-auto w-75">
            No users yet.
          </Alert>
        </Row>
      )}
    </Container>
  );
};

export default Deck;
