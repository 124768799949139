import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Explore as View, Loader } from '../../Components';
import { SearchIndexClient, AzureKeyCredential } from '@azure/search-documents';
import countries from '../../Utilities/countries';
import { getDistance, convertDistance } from 'geolib';
import tracker from '../../HOC/Tracker';

tracker();

const indexClient = new SearchIndexClient(
  'https://northword.search.windows.net',
  new AzureKeyCredential('1EF7B2FD57E5B1DC8F03C727222DCB70')
);

const searchClient = indexClient.getSearchClient('index');

const TellingStories = () => {
  const INITIAL_FILTER = {
    categories: [],
    languages: [],
    countries: [],
    distance: 2000
  };
  
  const INITIAL_LOCATION = { lat: 57.11897, long: -2.13766 };

  const [state, setState] = useState({
    stories: [],
    loading: false,
    show: false,
    filter: INITIAL_FILTER,
    categories: [],
    languages: [],
    index: 0,
    term: '',
    location: INITIAL_LOCATION
  });

  useEffect(() => {
    const getLocation = () => {
      navigator.geolocation.getCurrentPosition((position) =>
        setState((previousState) => ({
          ...previousState,
          location: {
            lat: position.coords.latitude,
            long: position.coords.longitude
          }
        }))
      );
    };

    const fetchStories = () => {
      const getStories = axios.get('/story?released=true');
      const getCategories = axios.get('/admin/category');
      const getLanguages = axios.get('/admin/language');

      axios.all([getStories, getCategories, getLanguages]).then(
        axios.spread((...responses) => {
          const STORIES_WITH_DISTANCE = responses[0].data.map(story => {
    
            const LONG = story.location?.coordinates[1] ?? 0;
            const LAT = story.location?.coordinates[0] ?? 0;

            story.distance = convertDistance(getDistance({latitude: LAT, longitude: LONG}, {latitude: state.location.lat, longitude: state.location.long}), 'mi')

            return story
          }).filter(story => !story.categories.includes("Making Stories") && !story.categories.includes("Using Stories"))
  
          setState((previousState) => ({
            ...previousState,
            stories: STORIES_WITH_DISTANCE,
            loading: false,
            categories: responses[1].data.filter((category) => category.category !== 'Using Stories' && category.category !== 'Making Stories').map((category) => category.category),
            languages: responses[2].data.map((language) => language.language)
          }));
        })
      );
    };
    getLocation();
    fetchStories();
  }, [state.location.lat, state.location.long]);


  const handleCategoryChange = (newValue) => {
    const chosen = newValue.map((category) => category.value);
 
    setState((previousState) => ({
      ...previousState,
      filter: { ...previousState.filter, categories: chosen }
    }));
  };

  const handleLanguageChange = (newValue) => {
    const chosen = newValue.map((language) => language.value);

    setState((previousState) => ({
      ...previousState,
      filter: { ...previousState.filter, languages: chosen }
    }));
  };

  const handleDistanceChange = ({ target: { value } }) => 
    setState((previousState) => ({
      ...previousState,
      filter: { ...previousState.filter, distance: value}
    })
    )


  const handleCountryChange = (newValue) => {
    const chosen = newValue.map((country) => country.value);

    setState((previousState) => ({
      ...previousState,
      filter: { ...previousState.filter, countries: chosen }
    }));
  };

  const convertToKM = miles => Math.round(miles* 1.609344);

  const buildFilter = () => {
    const FILTERS = []
      state.filter.categories.length > 0 && FILTERS.push(`categories/any(c: search.in(c, '${String(state.filter.categories)}'))`);
      state.filter.countries.length > 0 && FILTERS.push(`search.in(country, '${state.filter.countries}', ',')`);
    state.filter.languages.length > 0 && FILTERS.push(`content/any(c: search.in(c/language, '${state.filter.languages}'))`);
    FILTERS.push(`geo.distance(location, geography'POINT(${state.location.lat} ${state.location.long})') le ${convertToKM(state.filter.distance)}`);
    return FILTERS.join(' and ');
  }

  async function sendQueries(searchClient) {
    const FILTER_STRING = await buildFilter();
    let searchOptions = {
      includeTotalCount: true,
      select: ['*'],
      filter: FILTER_STRING,
    };

   let searchResults = await searchClient.search(state.term, searchOptions);
    const results = [];

    for await (const result of searchResults.results) {
      results.push(result.document);
    }

    return results;
  }

  const handleSearch = async (e) => {
    e.preventDefault();
    const RESULTS = await sendQueries(searchClient);
    const STORIES = RESULTS.filter(story => !story.categories.includes("Making Stories") && !story.categories.includes("Using Stories")).map(story => {
      const TEMP_STORY = story;
      TEMP_STORY.location.coordinates = [TEMP_STORY.location.longitude, TEMP_STORY.location.latitude];

      return TEMP_STORY
    })

    setState(prevState => ({...prevState, stories: STORIES}))
  };

  const handleTermChange = (e) =>
    setState((previousState) => ({ ...previousState, term: e.target.value }));

  const clear = () => {
 
   
  };

  const handleToggle = (value) => setState((previousState) => ({ ...previousState, index: value }))
  const handleClose = () => setState((previousState) => ({ ...previousState, show: false }));
  const handleShow = () => setState((previousState) => ({ ...previousState, show: true }))

  return state.loading ? (
    <Loader />
  ) : (
    <View
      stories={state.stories}
      categories={state.categories}
      countries={countries}
      languages={state.languages}
      handleClose={handleClose}
      handleShow={handleShow}
      handleCategoryChange={handleCategoryChange}
      handleLanguageChange={handleLanguageChange}
      handleCountryChange={handleCountryChange}
      handleToggle={handleToggle}
      show={state.show}
      filter={state.filter}
      index={state.index}
      handleSearch={handleSearch}
      term={state.term}
      handleTermChange={handleTermChange}
      handleDistanceChange={handleDistanceChange}
      location={state.location}
      clear={clear}
      type="Telling Stories"
    />
  );
};

export default TellingStories;