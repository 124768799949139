import React from 'react';
import { Row, Col, Card, Button, Container, Alert, Popover, OverlayTrigger } from 'react-bootstrap';
import { ImageUnavailable as Placeholder } from '../../../../Assets';
import Buttons from './Buttons';
import ScrollContainer from 'react-indiana-drag-scroll';

const Deck = ({ stories, name, handleShowSubmit, handleShowWithdraw, handleShowDelete }) => {
  const list = stories.map((story) => {
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">{story.content[0].title || 'Untitled'}</Popover.Title>

        <h5 className="popover-subtitle">{story.content[0].subtitle}</h5>
        <p className="popover-subtitle">
          Submitted By:{' '}
          <b>
            {story.username} ({story.organisation_submitting})
          </b>
        </p>
        <Popover.Content>{story.content[0].summary}</Popover.Content>
      </Popover>
    );

    return (
      <Col key={story.id} className="container-fluid mt-4">
        <Card className="story-card">
          <OverlayTrigger trigger={['hover', 'focus']} placement="auto-end" overlay={popover}>
            <div>
              <Card.Img variant="top" src={story.header || Placeholder} />
              <Card.Body>
                <Card.Title>{story.content[0].title || 'Untitled'}</Card.Title>
              </Card.Body>
            </div>
          </OverlayTrigger>
          <Card.Footer>
            <Buttons
              story={story}
              handleShowDelete={handleShowDelete}
              handleShowSubmit={handleShowSubmit}
              handleShowWithdraw={handleShowWithdraw}
            />
          </Card.Footer>
        </Card>
      </Col>
    );
  });

  return (
    <Container fluid className="mt-5  deck-container px-1">
      <Row className="justify-content-center">
        <Button disabled className="py-3 px-5 deck-header" variant="secondary">
          <h1>{name}</h1>
        </Button>
      </Row>
      {list.length ? (
       <Row>
          <ScrollContainer>
            <div className="deck d-flex pb-4">
              {list}
            </div>
          </ScrollContainer>
        </Row>
      ) : (
        <Row className="no-stories my-auto">
          <Alert variant="info" className="my-auto mx-auto w-75">
            No stories yet.
          </Alert>
        </Row>
      )}
    </Container>
  );
};

export default Deck;
