import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Categories as View, Loader } from '../../Components';
import SCHEMA from '../../Validation/category.schema';
import tracker from '../../HOC/Tracker';

tracker();

const Categories = () => {
  const initialState = {
    category: '',
    selection: '',
    categories: [],
    filtered: [],
    search: '',
    loading: true
  };

  const [state, setState] = useState(initialState);

  const handleSearch = (e) => {
    const CATEGORIES = state.categories.filter((category) =>
      category.category.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setState((previousState) => ({
      ...previousState,
      filtered: CATEGORIES
    }));
  };

  const handlePostSuccess = ({ data }) =>
    setState((previousState) => ({
      ...previousState,
      msg: 'Category added successfully!',
      variant: 'success',
      show: true,
      categories: [...previousState.categories, data],
      category: ''
    }));

  const handlePostError = ({ response: { status } }) => {
    const ERROR_MESSAGE =
      status === 409 ? 'Category already exists!' : 'Oops! There was a problem with that request';

    setState((previousState) => ({
      ...previousState,
      msg: ERROR_MESSAGE,
      variant: 'danger',
      show: true
    }));
  };

  const handleSubmit = (input) => {
    const DATA = { category: input.category };

    axios.post('/admin/category', DATA).then(handlePostSuccess).catch(handlePostError);
  };

  const handleDelete = () => {
    state.selection
      ? doDeletion()
      : setState((previousState) => ({
          ...previousState,
          msg: 'Please select a category to delete',
          variant: 'danger',
          show: true
        }));
  };

  const handleDeleteSuccess = () => {
    const newList = state.categories.filter((item) => item.category !== state.selection);

    setState((previousState) => ({
      ...previousState,
      msg: 'Category removed successfully!',
      variant: 'success',
      show: true,
      categories: newList,
      selection: ''
    }));
  };

  const handleDeleteError = () =>
    setState((previousState) => ({
      ...previousState,
      msg: 'Oops! There was a problem with that request.',
      variant: 'danger',
      show: true
    }));

  const doDeletion = () =>
    axios
      .delete(`/admin/category/${state.selection}`)
      .then(handleDeleteSuccess)
      .catch(handleDeleteError);

  const handleSelect = (e) =>
    setState((previousState) => ({
      ...previousState,
      selection: e
    }));

  useEffect(() => {
    axios.get('/admin/category').then(({ data }) => {
      setState((previousState) => ({
        ...previousState,
        categories: data,
        loading: false
      }));
    });
  }, []);

  return state.loading ? (
    <Loader />
  ) : (
    <View
      state={state}
      schema={SCHEMA}
      handleSearch={handleSearch}
      handleSubmit={handleSubmit}
      handleDelete={handleDelete}
      handleSelect={handleSelect}
    />
  );
};

export default Categories;
