import React, { useState, useEffect, useMemo } from 'react';
import Edit from './view';
import * as yup from 'yup';
import axios from 'axios';
import { Loader } from '../../../Components';
import tracker from '../../../HOC/Tracker';

tracker();

const EditUser = ({ token, computedMatch }) => {
  const OPTIONS = useMemo(
    () => ({
      headers: { Authorization: token }
    }),
    [token]
  );

  const INITIAL_STATE = {
    username: computedMatch.params.original,
    role: 'COLLABORATOR',
    organisation: '',
    show: false,
    variant: 'danger',
    msg: '',
    organisations: [],
    original: computedMatch.params.original,
    label: 'No',
    loading: true
  };
  const [state, setState] = useState(INITIAL_STATE);

  const handleFetchSuccess = (res) =>
    setState((previousState) => ({
      ...previousState,
      id: res[0].data[0].id,
      username: res[0].data[0].username,
      organisation: res[0].data[0].organisation,
      role: res[0].data[0].role,
      organisations: res[1].data.map((org) => org.organisation),
      loading: false
    }));

  useEffect(() => {
    const requestOrganisations = axios.get('/admin/organisation', OPTIONS);
    const requestUser = axios.get('/user/' + state.original, OPTIONS);

    axios
      .all([requestUser, requestOrganisations])
      .then(axios.spread((...res) => handleFetchSuccess(res)));
  }, [OPTIONS, state.original]);

  const handleCheck = () =>
    setState((previousState) => ({
      ...previousState,
      role: state.role === 'ADMIN' ? 'COLLABORATOR' : 'ADMIN'
    }));

  const handleSelect = (e, setFieldValue) => {
    setFieldValue('organisation', e);
    setState((previousState) => ({
      ...previousState,
      organisation: e
    }));
  };

  const handleEditSuccess = (username) =>
    setState((previousState) => ({
      ...previousState,
      msg: 'User details updated successfully!',
      variant: 'success',
      show: true,
      original: username
    }));

  const handleEditError = ({ response }) =>
    setState((previousState) => ({
      ...previousState,
      msg: response.status === 422 && response.data[0].msg,
      variant: 'danger',
      show: true
    }));

  const handleSubmit = (input) => {
    const DATA = {
      username: input.username,
      role: state.role,
      organisation: input.organisation
    };

    axios
      .put('/user/' + state.original, DATA, OPTIONS)
      .then(handleEditSuccess(DATA.username))
      .catch(handleEditError);
  };

  const SCHEMA = yup.object().shape({
    username: yup
      .string()
      .required('*Username is required')
      .min(3, '*Username must be between 3-25 characters')
      .max(25, '*Username must be between 3-25 characters')
      .matches(
        /^[a-zA-Z0-9_-]*$/,
        '*Username must only contain letters, numbers, hyphens or underscores.'
      ),
    organisation: yup
      .string()
      .required('*Organisation is required')
      .oneOf(state.organisations, '*Organisation invalid, please pick one from the list.'),
    role: yup
      .string()
      .required()
      .oneOf(['ADMIN', 'COLLABORATOR'], '*Role invalid, please select from the toggle.')
  });

  return state.loading ? (
    <Loader />
  ) : (
    <Edit
      schema={SCHEMA}
      state={state}
      handleSelect={handleSelect}
      handleSubmit={handleSubmit}
      handleCheck={handleCheck}
    />
  );
};

export default EditUser;
