import React, { useState, useEffect } from 'react';
import PrivateNavBar from './private';
import PublicNavBar from './public';
import axios from 'axios';

const NavBar = ({ handleLogout, loggedIn, admin, username }) => {
  const [state, setState] = useState({
    expanded: false,
    notifications: { products: 0, stories: 0 },
    loading: false,
    show: false
  });

  const logout = () => {
    handleLogout();
    setState((previousState) => ({ ...previousState, expanded: false }));
  };

  const handleSuccess = ({ data }) => {
    setState((previousState) => ({
      ...previousState,
      notifications: { stories: data.length },
      loading: false
    }));
  };

  useEffect(() => {
    if (admin) {
      setState((previousState) => ({ ...previousState, loading: true }));
      axios.get('/story?released=false&submitted=true').then(handleSuccess).catch(console.error);
    }
  }, [admin]);

  const renderNavBar = () =>
    loggedIn ? (
      <PrivateNavBar
        username={username}
        loggedIn={loggedIn}
        admin={admin}
        logout={logout}
        handleClick={(e, action) => (previousState) => ({ ...previousState, expanded: action })}
        notifications={state.notifications}
        show={state.show}
        setShow={() => setState(previousState => ({ ...previousState, show: true }))}
        handleCancel={() => setState(previousState => ({ ...previousState, show: false }))}
      />
    ) : (
      <PublicNavBar
        handleClick={(e, action) => (previousState) => ({ ...previousState, expanded: action })}
      />
    );

  return !state.loading && renderNavBar();
};

export default NavBar;
