import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome as Home } from '@fortawesome/free-solid-svg-icons';
import { SEO } from '../../Components';
import './404.scss';
import tracker from '../../HOC/Tracker';

tracker();

const NotFound = () => (
  <>
    <SEO title='Not Found' description="HTTP Error 404: Page Not Found" />
    <Container fluid className="not-found">
      <Row className="h-100">
        <div className="backdrop my-auto mx-5">
          <h1 className="text multiply">
            <p>404: Page Not Found :(</p>
          </h1>
        </div>
        <br />

        <div className="mx-auto">
          <Button as={Link} to="/">
            <FontAwesomeIcon className="mr-2" icon={Home} />
            Go Home
          </Button>
        </div>
      </Row>
    </Container>
  </>
);
export default NotFound;
