import React, { useState } from 'react';
import { Jumbotron, Container, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import AudioPlayer from 'react-audio-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload as Upload } from '@fortawesome/free-solid-svg-icons';

const Audio = ({ state, setState, token, language, index, user, type }) => {
  const [state2, setState2] = useState({
    file: null,
    show: false,
    message: '',
    variant: 'success'
  });

  const handleError = () => {
    setState2((previousState) => ({
      ...previousState,
      show: true,
      variant: 'danger',
      message: 'Audio clip failed to upload. Please try again.'
    }));
  };

  const handleSuccess = ({ data }) => {
    setState2((previousState) => ({
      ...previousState,
      show: true,
      variant: 'success',
      message: 'Audio clip uploaded successfully.'
    }));

   

      const TEMP_STATE = state.story;
      TEMP_STATE.content[index].audio = data;
      setState((previousState) => ({ ...previousState, key: language, story: TEMP_STATE }));

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const id = uuid();
    formData.append('image', state2.file[0]);
    formData.append('username', user);
    formData.append('title', id);
    formData.append('type', type);
    formData.append('audio', 'true');

    const headers = {
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data'
      }
    };

    axios.post('/media', formData, headers).then(handleSuccess).catch(handleError);
  };

  const renderPreview = () => {
    if (state2.file && state2.file.length > 0) {
      return <AudioPlayer src={URL.createObjectURL(state2.file[0])} controls />;
    } else if (state[type]?.content[index].audio) {
      return <AudioPlayer src={state[type]?.content[index].audio} controls />;
    }
  };

  return (
    <Container className="small row m-0 p-0" fluid>
      <Jumbotron className="small-mob my-auto  h-sm-100 mx-md-auto mx-0 w-md-75 w-100">
        <h1>Upload Audio File</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formAudio">
            <Form.File
              id="audio"
              onChange={(e) =>
                setState2((previousState2) => ({ ...previousState2, file: e.target.files }))
              }
              accept="audio/*"
            />
            <Form.Text muted>Add an audio narration.</Form.Text>
          </Form.Group>

          <Form.Group>{renderPreview()}</Form.Group>

          <Form.Group>
            <Button variant="primary" type="submit">
              <FontAwesomeIcon icon={Upload} className="mr-1" />
              Upload
            </Button>
          </Form.Group>

          <Alert show={state2.show} variant={state2.variant}>
            {state2.message}
          </Alert>
        </Form>
      </Jumbotron>
    </Container>
  );
};

export default Audio;
