import React from 'react';
import { Jumbotron, Container, Form } from 'react-bootstrap';
import Select from 'react-select';
const LanguagesView = ({ handleLanguageChange, languages, options, type }) => (

  <Container className="small row m-0 p-0" fluid>
    <Jumbotron className="small-mob my-auto  h-sm-100 mx-md-auto mx-0 w-md-75 w-100">
      <h1>Languages</h1>
      <Form>
        <Form.Group controlId="formBasicLanguage">
          <Form.Label>Add a language</Form.Label>

          <Form.Control
            name="language"
            placeholder="Select a language..."
            as={Select}
            options={options}
            custom
            isMulti
            maxMenuHeight={200}
            onChange={handleLanguageChange}
            value={languages}
            classNamePrefix="select"
            isOptionDisabled={(option) => option.disabled}
          />

          <Form.Text muted>Optional. Add any translations or dialects for the {type}.</Form.Text>
        </Form.Group>
      </Form>
    </Jumbotron>
    </Container>
);

export default LanguagesView;
