import * as yup from 'yup';
import axios from 'axios';

export default async () => {
  let categories = [];
  let languages = [];

  const requestCategories = axios.get('/admin/category');
  const requestLanguages = axios.get('/admin/language');

  await axios.all([requestCategories, requestLanguages]).then(
    axios.spread((...responses) => {
      categories = responses[0].data.map((category) => category.category);
      languages = responses[1].data.map((language) => language.language);
    })
  );

  const CATEGORY_SCHEMA = yup.string().oneOf(categories);
  const LANGUAGE_SCHEMA = yup.string().oneOf(languages);
  const KEYWORD_SCHEMA = yup
    .string('*Keywords must be textual.')
    .min(3, '*Keywords must be between 3-15 characters')
    .max(25, '*Keywords must be between 3-25 characters');

  const CONTENT_SCHEMA = yup.object().shape({
    title: yup
      .string('*Title must be textual.')
      .test(
        'titlePresentAndCorrect',
        '*Title must be between 2-50 characters long.',
        (value) => value.length >= 2 && value.length <= 50
      ),
    subtitle: yup
      .string('*Subtitle must be textual.')
      .test(
        'subtitlePresentAndCorrect',
        '*Subtitle must be between 2-50 characters long.',
        (value) => value === '' || (value.length >= 2 && value.length <= 50)
      ),
    summary: yup
      .string('*Summary must be textual.')
      .test(
        'summaryPresentAndCorrect',
        '*Summary must be between 10-250 characters long.',
        function checkEnglishSummary(value) {
          return this.path === 'content[0].summary'
            ? value.length >= 10 && value.length <= 250
            : value === '' || (value.length >= 10 && value.length <= 250);
        }
      ),
    story_text: yup
      .string()
      .test(
        'storyTextPresentAndCorrect',
        '*Story text must be between 25-20,000 characters long.',
        (value) => value === '' || (value.length >= 25 && value.length <= 200_000)
      )
  });


  return yup.object().shape({
    content: yup.array().required('*English translation is required.').of(CONTENT_SCHEMA),
    categories: yup.array().min(1, '*At least one category is required.').of(CATEGORY_SCHEMA),
    author: yup
      .string('*Author must be textual.')
      .test(
        'authorPresentAndCorrect',
        '*Author must be between 3-30 characters long.',
        (value) => value === '' || (value.length >= 3 && value.length <= 30)
      ),
    keywords: yup.array().min(1, '*At least one keyword is required.').of(KEYWORD_SCHEMA),
    location_name: yup
      .string('*Location name must be textual.')
      .required('*Location name is required.')
      .min(3, '*Location name must be between 3-40 characters long.')
      .max(40, '*Location name must be between 3-40 characters long.'),
    country: yup
      .string('*Country must be textual.')
      .required('*Country is required.')
      .min(2, '*Country must be between 3-40 characters long.')
      .max(40, '*Country must be between 3-40 characters long.'),
    location: yup.object().shape({
    type: yup.string().matches(/(Point)/),
    coordinates: yup.array().of(yup.number('*Coordinates must be a number. The marker must be placed incorrectly.')
      .required('*Coordinate is required. Please plot a marker on the map.')
      .test(
        'length',
        '*Coordinates are incorrect length. Please plot a marker on the map.',
        (val) => val.toString().length > 8
      ))
  })
,
    languages: yup.array().of(LANGUAGE_SCHEMA)
  });
};
