import React, { useState } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';
import SearchInput from '../Places';
import './style.scss';
import * as yup from 'yup';

const MapContainer = ({ state, setState, google, lat, long }) => {
  const [zoom, setZoom] = useState(5);
  const DEFAULT_LAT = 59.540331856806795,
    DEFAULT_LONG = 16.96201370971681;
  const onMarkerDragEnd = (coord, index) => {
    const { latLng } = coord;
    const lat = latLng.lat(),
      lng = latLng.lng();

    setState((previousState) => ({
      ...previousState,
      story: { ...previousState.story, latitude: lat, longitude: lng }
    }));
  };

  return (
    <Container className="align-items-center small row m-0 p-0" fluid>
      <Row
        xs={1}
        md={2}
        lg={2}
        className="my-lg-5 my-0 h-sm-100 mx-lg-auto mx-0 w-lg-75 w-100 no-gutters"
      >
        <Col>
          <Jumbotron
            fluid
            style={{
              height: '100%',
              width: '100%'
            }}
          >
            <Container>
              <SearchInput
                setState={setState}
                state={state}
                setZoom={setZoom}
                schema={yup.object().shape({
                  country: yup
                    .string('*Country must be textual.')
                    .test(
                      'countryPresentAndCorrect',
                      '*Country must be between 3-40 characters long.',
                      (value) => value?.length >= 3 && value?.length <= 40
                    ),
                  location_name: yup
                    .string('*Location name must be textual.')
                    .test(
                      'locationNamePresentAndCorrect',
                      '*Location name must be between 3-40 characters long.',
                      (value) => value === '' || (value?.length >= 3 && value?.length <= 40)
                    )
                })}
              />
            </Container>
          </Jumbotron>
        </Col>
        <Col>
          <div id="map-container">
            <Map
              google={google}
              zoom={zoom}
              center={{
                lat: state.story.latitude || DEFAULT_LAT,
                lng: state.story.longitude || DEFAULT_LONG
              }}
              initialCenter={{
                lat: lat || DEFAULT_LAT,
                lng: long || DEFAULT_LONG
              }}
            >
              <Marker
                onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
                draggable={true}
                position={{
                  lat: state.story.latitude || DEFAULT_LAT,
                  lng: state.story.longitude || DEFAULT_LONG
                }}
              />
            </Map>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyClnSujL8ebJ_tAadgbEU9p0zRJ8Ke6FVI'
})(MapContainer);
